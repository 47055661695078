class PlaygroundService {
    urlBasicPath: string = "https://api.phi.wtf/";
    //urlBasicPath: string = "http://localhost:8111/";
    constructor() {
    }
    async loadPlaygroundImages(
        type: string, directoryName: string, imgId: string
    ): Promise<string> {
        //debugger;
        let imagePath: string = "";
        let headers = new Headers();
        let playgroundImageUrl = this.urlBasicPath + "api/getPlayGoundImages";

        let params: { [key: string]: any } = {
            'typeOfTrait': type, 'directoryName': directoryName, 'imgId': imgId
        };
        var formBody: any[] = [];
        for (let param in params) {

            var encodedKey = encodeURIComponent(param);
            var encodedValue = encodeURIComponent(params[param]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        let bodyParams: string = formBody.join("&");
        //console.log(bodyParams);
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('authorization', process.env.REACT_APP_Token as string);
        //headers.append('Origin', 'http://localhost:3000');
        const requestOptions = {
            method: 'POST',
            headers: headers,
            //headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: bodyParams
        };
        fetch(playgroundImageUrl, requestOptions)
            .then(response => response.json())
            .then((data) => {
                imagePath = data.data;
                return imagePath;
            });

        return imagePath;

    }
    async generateImage(
        numberOfImages: string, typeOfTrait: string, background: string,
        platform: string, arms: string, hand_props: string,
        body: string, head: string, bottoms: string,
        tops: string, shoes: string, hair: string,
        accessories: string, imgId: any, width: string, height: string
    ) {
        //debugger;
        let headers = new Headers();
        let result: string = "";
        let playgroundImageUrl = this.urlBasicPath + "api/generatePlaygroundImage";
        //debugger;
        if (typeOfTrait == "None") {
            typeOfTrait = "";
        }
        if (background == "None" || background == undefined) {
            background = "";
        }
        if (platform == "None" || platform == undefined) {
            platform = "";
        }
        if (arms == "None") {
            arms = "";
        }
        if (hand_props == "None") {
            hand_props = "";
        }
        if (body == "None") {
            body = "";
        }
        if (head == "None") {
            head = "";
        }
        if (bottoms == "None") {
            bottoms = "";
        }
        if (tops == "None") {
            tops = "";
        }
        if (shoes == "None") {
            shoes = "";
        }
        if (hair == "None") {
            hair = "";
        }
        if (accessories == "None") {
            accessories = "";
        }
        //debugger;
        let params: { [key: string]: any } = {
            'numberOfImages': numberOfImages, 'typeOfTrait': typeOfTrait,
            'background': background, 'platform': platform, 'arms': arms,
            'hand_props': hand_props, 'body': body, 'head': head,
            'bottoms': bottoms, 'tops': tops, 'shoes': shoes,
            'hair': hair, 'accesories': accessories,
            'imgId': imgId, 'width': width, 'height': height, "isPlayground": "1"
        };
        /*let params: { [key: string]: any } = {
            'numberOfImages': numberOfImages, 'typeOfTrait': typeOfTrait,
            'background': "", 'platform': "", 'arms': "",
            'hand_props': "", 'body': "", 'head': head,
            'bottoms': "", 'tops': "", 'shoes': "",
            'hair': hair, 'accesories': "",
            'imgId': imgId, 'width': width, 'height': height, "isPlayground": "1"
        };*/
        //debugger;
        debugger;
        var formBody: any[] = [];
        for (let param in params) {

            var encodedKey = encodeURIComponent(param);
            var encodedValue = encodeURIComponent(params[param]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        //debugger;
        let bodyParams: string = formBody.join("&");
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('authorization', process.env.REACT_APP_Token as string);

        const requestOptions = {

            method: 'POST',
            //headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            headers: headers,
            body: bodyParams
        };
        await fetch(playgroundImageUrl, requestOptions)
            .then(response => response.json())
            .then((data) => {
                result = data.data;
                //debugger;
                return result;
            });

        //return result;
        //return "done";
    }
    async loadGeneratedImages(
        type: string, directoryName: string, imgId: string
    ) {
        debugger;
        let headers = new Headers();
        let imagePath: string = "";
        let traitDirectoryUrl = this.urlBasicPath + "api/getPlayGoundImages";

        let params: { [key: string]: any } = {
            'typeOfTrait': type, 'directoryName': directoryName, 'imgId': imgId
        };
        var formBody: any[] = [];
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('authorization', process.env.REACT_APP_Token as string);
        for (let param in params) {

            var encodedKey = encodeURIComponent(param);
            var encodedValue = encodeURIComponent(params[param]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        let bodyParams: string = formBody.join("&");
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: bodyParams
        };
        //debugger;
        await fetch(traitDirectoryUrl, requestOptions)
            .then(response => response.json())
            .then((data) => {
                //debugger;
                imagePath = data.data;
                return imagePath;
            });
    }

}

const playgroundService = new PlaygroundService();
export default playgroundService;