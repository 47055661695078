import React, { FC, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import EventEmitter from "../utils/eventService";
export interface ButtonViewProps {
  testProp: string;
}
export const PlaygroundButtons: FC<ButtonViewProps> = ({ testProp }) => {
  const [isLoading, setIsLoading] = useState(false);
  const downloadHandler = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    //debugger;
    e.preventDefault();
    EventEmitter.emit('downloadImageListener', { params: '1' });
    //debugger;
  };
  const feelHungryHandler = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    //debugger;
    e.preventDefault();
    EventEmitter.emit('feelHungryListener', { params: '1' });
    //EventEmitter.emit('startStopLoading',{params:'1'});
  };
  const generateImageHandler = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    //debugger;
    e.preventDefault();
    EventEmitter.emit('generateImageListener', { params: '1' });
    //EventEmitter.emit('startStopLoading',{params:'1'});
  };
  return (
    <>
      <div className="flex justify-center sm:flex-row flex-639px-column gap-[10px] my-[20px]">
        <Button
          onClick={downloadHandler}
          className="bg-btnbg rounded py-3 px-5 hover:bg-btnbg flex justify-center items-center text-center border-gradient "
        >
          Download
        </Button>
        <Button
          onClick={feelHungryHandler}
          className="bg-btnbg rounded py-3 px-5 hover:bg-btnbg flex justify-center items-center text-center border-gradient ">
          Randomize
        </Button>
        <Button
          onClick={generateImageHandler}
          className="bg-btnbg rounded py-3 px-5 hover:bg-btnbg flex justify-center items-center text-center border-gradient button-display-none">
          Generate
        </Button>
      </div>
    </>
  );
}