import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Dialog } from "@mui/material";
import { sbTokenSchema } from "../../utils/schemas";

import React, { useEffect, useState } from "react";
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { useNavigate, useParams } from "react-router-dom";
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults';
import { walletAdapterIdentity as umiWalletAdapterIdentity } from '@metaplex-foundation/umi-signer-wallet-adapters';
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import { FtAccordionItem } from "../../components/common/FtAccordionItem/FtAccordionItem";

type FormValues = {
  name: string;
  symbol: string;
  description: string;
};

const MintSBToken = () => {
  const { connection } = useConnection();
  const [isLoading, setIsLoading] = useState(false);
  const wallet = useWallet();
  const navigate = useNavigate();
  const [nftDetail, setNftDetail] = useState("" as any);
  //const { tokenAddress } = useContext(tokenContext);
  const { faucetId, collectionAddress } = useParams();
  const [success, setSuccess] = useState(false);
  //debugger;


  useEffect(() => {
    //console.log("token_address",tokenAddress);
    /*if (!tokenAddress) {
      navigate("/faucetlist");
    }*/
    //console.log(wallet.publicKey?.toBase58());
    //console.log(faucetId);
    //console.log(collectionAddress);
    //if(collectionAddress!=undefined){
    loadNftDetails();
    //}

  }, [nftDetail]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(sbTokenSchema),
  });
  const loadNftDetails = async () => {
    if (collectionAddress !== undefined) {
      var formBody: any[] = [];
      formBody.push("nft_address" + "=" + collectionAddress.toString());
      let bodyParams: string = formBody.join("&");
      console.log("bodyParams", bodyParams);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded', authorization: process.env.REACT_APP_Token as string },
        body: bodyParams
      };
      fetch(`${process.env.REACT_APP_URL}/api/nftDetail`, requestOptions)
        .then(response => response.json())
        .then((data) => {
          //debugger;
          setNftDetail(data.data);
        }).catch((err) => {
          //debugger;
          console.log("Error:" + err);
        });
    }
  };
  //const onSubmit = async (values) => {
  const onSubmit = async (values) => {

    if (wallet.connected && wallet.publicKey !== undefined) {
      try {
        const umi = createUmi('https://api.devnet.solana.com')
          .use(umiWalletAdapterIdentity(wallet))
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            authorization: process.env.REACT_APP_Token as string,
          },
          body: new URLSearchParams(
            Object.entries({
              ...values,
              collection_mint_address: collectionAddress,
              faucet_id: faucetId,
              owner_address: wallet.publicKey?.toBase58(),
            })
          ).toString(),
        };
        //console.log(process.env.REACT_APP_URL);
        //console.log(requestOptions); 
        setIsLoading(true);
        const res = await fetch(
          `${process.env.REACT_APP_URL}/api/mintSBNft`,
          requestOptions
        );
        setIsLoading(false);
        const data = await res.json();
        if (data?.statusCode == 200) {
          console.log(data.data);
          let deserialized_obj_mint = umi.transactions.deserialize(Buffer.from(data.data.buffer_string, 'base64'));

          let signed_transactions = await umi.identity.signAllTransactions([deserialized_obj_mint]);

          await umi.rpc.sendTransaction(signed_transactions[0], { skipPreflight: true }).then(async (signature_mint) => {
            const confirmResult_mint = await umi.rpc.confirmTransaction(signature_mint, {
              strategy: { type: 'blockhash', ...(await umi.rpc.getLatestBlockhash()) }
            });
            //console.log(confirmResult_mint.value);
            /*if (confirmResult_mint.value.confirmationStatus == "confirmed") {
              console.log("worked");
            }*/

            const updateRequestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                authorization: process.env.REACT_APP_Token as string,
              },
              body: new URLSearchParams(
                Object.entries({
                  ...values,
                  collectionaddress: data.data.collectionaddress,
                  description: data.data.description,
                  faucet_id: data.data.faucet_id,
                  nftaddress: data.data.nftaddress,
                  nfturi: data.data.nfturi,
                  title: data.data.title,
                  walletAddress: data.data.walletAddress,
                  totalMinted: data.data.totalMinted,
                  allowedSize: data.data.allowedSize,
                })
              ).toString(),
            };

            const res_update = await fetch(
              `${process.env.REACT_APP_URL}/api/updateFaucetRecord`,
              updateRequestOptions
            );
            const dataUpdate_data = await res_update.json();
            if (dataUpdate_data?.statusCode == 200) {
              debugger;
              setSuccess(true);
            }
            else if (dataUpdate_data?.statusCode == 400) {
              toast.error(dataUpdate_data.message);
            }
          }).catch((err) => {
            console.log(err);
          });
          //let trans_obj = Transaction.from(new Uint8Array(Buffer.from(data.data.data, 'base64')));
          setSuccess(true);
        }
        else if (data?.statusCode == 400) {
          toast.error(data.message);
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      toast.error("Please connect your wallet first.");
    }

  };

  return (
    <>
      <section className={'section mint-sb-token'} data-name={'section'} data-section={'section-mint-sb-token'}>

        <div className={'container section-title text-left'} >
          {/*<div className="section-before-title">All Proposals</div>*/}
          <h1 className={'section-title--h1'} >
            <span>Mint </span> <span className={'ft-text-gradient--special-1'}>SB Token</span>
          </h1>
          {/*<div  className={'section-subtitle'} >
              <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </span>
            </div>*/}
        </div>

        <div className="container section-content">


          {/*<form onSubmit={handleSubmit(onSubmit)}>*/}
          {/*<div className=" px-9 py-5 border rounded-md   mt-[50px]">
              <FormControl fullWidth className="mb-4">
                <InputField
                  fullWidth
                  id="standard-basic"
                  label="Name"
                  variant="standard"
                  {...register("name")}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              </FormControl>
              <FormControl fullWidth className="mb-4">
                <InputField
                  fullWidth
                  id="standard-basic"
                  label="Symbol"
                  variant="standard"
                  {...register("symbol")}
                  error={!!errors.symbol}
                  helperText={errors.symbol?.message}
                />
              </FormControl>
              <StyledTextarea
                placeholder="Description"
                minRows={3}
                {...register("description")}
              />
              {errors.description && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {errors.description.message}
                </p>
              )}
            </div>*/}





          <div className="mint--grid">

            <div className="mint--grid--img">
              {nftDetail ? (
                <img className="d-block w-30" src={nftDetail.json.image} />
              ) : ''
              }
            </div>


            <div className="mint--grid--content">

              {nftDetail ? (
                <div className="mint--grid--nft-title">
                  <h2>
                    <span className="mint--grid--nft-title--label">NFT Title: </span>
                    <span className="mint--grid--nft-name ft-text-gradient--special-1">{nftDetail.json.name}</span>
                  </h2>
                </div>
              ) : ''
              }
              {nftDetail ? (
                <div className="mint--grid--text">
                  <div className="ft-bg"></div>
                  <div className="mint--grid--text--inner">
                    <span className="font-bold">Detail</span>: {nftDetail.json.description}
                  </div>
                </div>
              ) : ''
              }
              {/*<div className="mint--grid--text">
                <div className="ft-bg"></div>
                <div className="mint--grid--text--inner">
                  <span className="font-bold">Tip</span>: Lorem Ipsum is
                  simply dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the industry's standard dummy text ever since the
                  1500s, when an unknown printer took a galley of type and scrambled
                  it to make a type specimen book. It has survived not only five
                  centuries, but also the leap into electronic typesetting,
                  remaining essentially unchanged. It was popularised in the 1960s
                  with the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software like
                  Aldus PageMaker including versions of Lorem Ipsum
                </div>
              </div>*/}


              <div className="mint--grid--button-container">
                <a className="ft-button " data-name="ftButton" data-component-style="1" aria-label="Mint Token" onClick={onSubmit}>
                  MINT TOKEN
                </a>
              </div>

            </div>

            {/*}
            {nftDetail ? (<><p className="text-[25px] mt-6 font-bold text-white">NFT Title:{nftDetail.json.name}</p><img className="d-block w-30" src={nftDetail.json.image} /></>) : (
              <><p className="text-[25px] mt-6 font-bold text-white">NFT Title</p><img src="" alt="" className="h-4 w-4" /></>
            )}
            <p className="mt-6 text-justify text-[20px] bg-gray text-lggray p-4 border rounded-md  ">
              <span className="text-black font-bold">Tip</span>: Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled
              it to make a type specimen book. It has survived not only five
              centuries, but also the leap into electronic typesetting,
              remaining essentially unchanged. It was popularised in the 1960s
              with the release of Letraset sheets containing Lorem Ipsum
              passages, and more recently with desktop publishing software like
              Aldus PageMaker including versions of Lorem Ipsum
            </p>
          */}


          </div>
          {/*</form>*/}

        </div>

      </section>


      <section className={'section'} data-name={'section'} data-section={'section-mint-faq'}>
        <div className={'container section-title text-center'} >
          <h1 className={'section-title--h1'} >
            <span>FAQ </span> {/*<span className={'ft-text-gradient--home-2'}>DAO</span>*/}
          </h1>
          <div className={'section-subtitle'} >
            <p>
              Find your answers to our most popular questions.
            </p>
          </div>
        </div>

        <div className={'container section-content'} >

          <div className={'ft-mint-faq'}>

            <FtAccordionItem
              title={'What is a Phigital?'}
              content={`Phigital is a decentralized autonomous organization (DAO) on the Solana Blockchain. Phigital is a product of the Furlough community. Furlough is a community of entrepreneurs and marketers who collaborate online.`}
            />

            <FtAccordionItem
              title={'What is a Phigital token?'}
              content={`Majority of Phigital tokens are non transferable which represent characteristics of the holder of our tokens. These non transferable create an identity and reputation throughout our ecosystem which enables more opportunities for collaboration.`}
            />

            <FtAccordionItem
              title={'How can I earn Phigital tokens?'}
              content={`You can earn Phigital tokens by attending events, engaging with the community, getting endorsed by other members, contributing to projects, providing services and learning.`}
            />

            <FtAccordionItem
              title={`How can I mint a Phigital token?`}
              content={`To get started you’ll need a Phantom wallet. Once you’ve created your wallet this will be treated as your login. You’ll need to have some Solana in your wallet to pay the transaction fees which come out to less than a penny. When you have the wallet installed, you simply click the mint page and accept the transaction. You’ll then be able to see your token with a wallet.`}
            />

            <FtAccordionItem
              title={'What does the token give me?'}
              content={`Our tokens are tied to a numerical value that is calculated on our public leaderboard. These tokens are treated as points to show our member ratings and soon will be treated as accolades to showcase as your Web 3 resume.`}
            />

            <FtAccordionItem
              title={'Can I mint more than one token?'}
              content={`Most tokens are mostly limited to one per wallet while others are allowed to minted once every 24 hours. In some cases, there is a possibility that you can mint more than one. In most cases, no.`}
            />

          </div>
        </div>
      </section>




      {isLoading == true && <Loader message="Minting Token" />}
      <Dialog onClose={() => setSuccess(false)} open={success}>
        <div className="p-5">
          <h2 className="mb-4">Token Sucessfully Minted</h2>

          {/*<button
            className="px-3 py-2 bg-[#212342] text-white rounded-md"
            onClick={() => navigate("/admin/faucetlist")}
          >
            Close
          </button>*/}
          <button
            className="ft-button"
            data-component-style="1"
            onClick={() => navigate("/")}
          >
            Close
          </button>
        </div>
      </Dialog>
    </>
  );
};

export default MintSBToken;
