import { useEffect, useState } from "react";
import { InputField } from "../../components/CutomUiFields";
import { zodResolver } from "@hookform/resolvers/zod";
import { fundReloadSchema } from "../../utils/schemas";
import { useNavigate } from "react-router-dom";
import { Dialog, FormControl } from "@mui/material";
import { useForm} from "react-hook-form";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { toast } from "react-toastify";
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults';
import { walletAdapterIdentity as umiWalletAdapterIdentity } from '@metaplex-foundation/umi-signer-wallet-adapters';
import { Transaction, LAMPORTS_PER_SOL, SystemProgram, sendAndConfirmTransaction, PublicKey, Keypair } from "@solana/web3.js";
import { Metaplex, walletAdapterIdentity } from "@metaplex-foundation/js";
import icon_wallet from './assets/digital-wallet.png';
type FormValues = {
  //wallet_address: string;
  amount: string;
};

export const FundReload = () => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(fundReloadSchema),
  });

  useEffect(() => {
    (async () => {
      try {

      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  const onSubmit = async (values) => {
    //debugger;
    if (wallet.connected && wallet.publicKey !== undefined && wallet.publicKey !== null) {
      try {
        const metaplex = Metaplex.make(connection).use(
          walletAdapterIdentity(wallet)
        );
        let availableBalance = await metaplex.connection.getBalance(wallet.publicKey);
        availableBalance = availableBalance / LAMPORTS_PER_SOL;
        if (availableBalance > values.amount) {
          let fromPublicKey = wallet.publicKey?.toString();
          const umi = createUmi('https://api.devnet.solana.com')
            .use(umiWalletAdapterIdentity(wallet))
          const transaction = new Transaction().add(
            SystemProgram.transfer({
              fromPubkey: new PublicKey(fromPublicKey),
              toPubkey: new PublicKey(process.env.REACT_APP_WALLET_ADDRESS!),
              lamports: LAMPORTS_PER_SOL * parseFloat(values.amount),
            })
          );
          let blockhash=(await connection.getLatestBlockhash()).blockhash;
          transaction.recentBlockhash=blockhash
          //transaction.feePayer=metaplex.identity();
          transaction.feePayer=metaplex.identity().publicKey;
          const signedTransaction = await metaplex.identity().signTransaction(transaction);
          metaplex.rpc().sendTransaction(signedTransaction, { skipPreflight: true }).then((resp) => {
            //debugger;
            //loadNftBids();
            setSuccess(true);
          }).catch((err) => {
           // debugger;
            setSuccess(false);
          })
          console.log("SIGNATURE", signedTransaction);
        }
        else {
          debugger;
          //setErrorMessage("You must have enough balance in your wallet to transfer.");
        }
      } catch (error) {
        toast.error("SOL Transfer Error:");
      }
    } else {
      toast.error("Please connect your wallet first.");
    }
  };


  return (
    <>
      <section className="create__faucet">
        <div className={'container container-form section-container-form'}>
          <div className="ft-form-container">
            <div className="ft-bg"></div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="ft-form">
                <div className={'ft-form-title'}>
                  Transfer Funds
                </div>
                <div className={'ft-wallet--item-block ft-wallet--address'} >
                <div className={'ft-bg'}></div>
                <div className={'ft-wallet--inner ft-wallet--address-inner'}>
                  <div className={'ft-wallet--item'}>
                    <div className={'ft-wallet--item-title'}>Wallet Address:</div>
                    <div className={'ft-wallet--item-icon-text'}>
                      <img className={'ft-wallet--icon'} src={icon_wallet} alt={''}/>
                      <span className={'ft-wallet--address-line'}>{process.env.REACT_APP_WALLET_ADDRESS}</span>
                    </div>
                  </div>
                </div>
              </div>
                {/*<FormControl fullWidth className="mb-4">
                  <InputField
                    fullWidth
                    id="standard-basic"
                    label="Wallet Address"
                    variant="standard"
                    {...register("wallet_address")}
                    error={!!errors.wallet_address}
                    helperText={errors.wallet_address?.message}
                  />
                </FormControl>*/}
                <FormControl fullWidth className="mb-4">
                  <InputField
                    fullWidth
                    id="standard-basic"
                    label="Amount"
                    variant="standard"
                    {...register("amount")}
                    error={!!errors.amount}
                    helperText={errors.amount?.message}
                  />
                </FormControl>
                <div className="">
                  <button className="ft-button w-full" data-component-style={"1"}>
                    Transfer
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Dialog onClose={() => setSuccess(false)} open={success} className={'ft-dialog'}>
        <div className="">
          <h2 className="text-center">Funds Transferred Successfully</h2>
        </div>
      </Dialog>
    </>
  );
};
