import { useEffect, useState } from "react";
import { useConnection} from '@solana/wallet-adapter-react';
import { toast } from "react-toastify";
import { LAMPORTS_PER_SOL,PublicKey} from "@solana/web3.js";
import icon_solana from './assets/solana.png';
import icon_wallet from './assets/digital-wallet.png';
import {SaButton} from "../../components/common/saButton/saButton";

export const WalletFund = () => {
  const { connection } = useConnection();
  const [availableBalance, setAvailableBalance] = useState(0);
  useEffect(() => {
    (async () => {
      try {
            try {
              let availableBalanceTemp = await connection.getBalance(new PublicKey(process.env.REACT_APP_WALLET_ADDRESS!));
              setAvailableBalance(availableBalanceTemp / LAMPORTS_PER_SOL);
              //debugger;
            } catch (error) {
              toast.error("Balance Checking Error:");
            }

      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <>
      <section className={'section ft-wallet'} data-name={'section'} data-section={'section-wallet'}>
        {/*<div className={'container section-title text-left'} >
          <h1  className={'section-title--h1'} >
            <span className={'ft-text-gradient--special-1'}>FURLOUGH'S</span> <span>WALLET</span>
          </h1>
        </div>*/}
        <div className={'container section-content'}>
        <div className="ft-special-header">
            <h2 className="ft-special-header--title"><span className={'ft-text-gradient--special-1'}>FURLOUGH'S</span> WALLET</h2>
            <SaButton link={`/admin/fundreload`} title={`Reload Funds`}  ariaLabel={`Reload Funds`} />
          </div>
          <div className="ft-special-content">

            <div className={'ft-wallet--items'}>

              <div className={'ft-wallet--item-block ft-wallet--balance'} >
                <div className={'ft-bg'}></div>
                <div className={'ft-wallet--inner ft-wallet--balance-inner'}>
                  <div className={'ft-wallet--item'}>
                    <div className={'ft-wallet--item-title'}>Balance:</div>
                    <div className={'ft-wallet--item-icon-text'}>
                      <img className={'ft-wallet--icon'} src={icon_solana} alt={''}/>
                      <span>{availableBalance} SOL</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className={'ft-wallet--item-block ft-wallet--address'} >
                <div className={'ft-bg'}></div>
                <div className={'ft-wallet--inner ft-wallet--address-inner'}>
                  <div className={'ft-wallet--item'}>
                    <div className={'ft-wallet--item-title'}>Wallet Address:</div>
                    <div className={'ft-wallet--item-icon-text'}>
                      <img className={'ft-wallet--icon'} src={icon_wallet} alt={''}/>
                      <span className={'ft-wallet--address-line'}>{process.env.REACT_APP_WALLET_ADDRESS}</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>



            <div className={'ft-wallet--item-block ft-wallet--content'} >
              <div className={'ft-bg'}></div>
              <div className={'ft-wallet--inner ft-wallet--content-inner'}>

                <div className={'ft-wallet--item-title'}>ISSUING TOKENS:</div>
                <p>In order to deploy and destroy tokens, the wallet address above must stay funded.</p>
                <p>This is the wallet that Solana creates for your entity upon sign up, and is used to fund any on-chain that you make through Solana.</p>
                <p>If you have any questions or concerns regarding your wallet and its contents, you can contact us at info@furlough.com</p>
              </div>
            </div>
          </div>

        </div>

      </section>

    {/*
    <section className="create_token">
      <div className="container">
        <div className="grid grid-cols-1  text-white">
          <p className="text-[25px] mt-6 font-bold text-white">
            Furlough's Wallet
          </p>
          <p className="mt-6 text-justify text-[20px] bg-gray text-lggray p-4 border rounded-md">
            <span className="text-black font-bold">Balance</span>: {availableBalance} SOL
          </p>
          <p className="mt-6 text-justify text-[20px] bg-gray text-lggray p-4 border rounded-md">
            <span className="text-black font-bold">Wallet Address</span>: {process.env.REACT_APP_WALLET_ADDRESS}
          </p>
          <p className="mt-6 text-justify text-[20px] bg-gray text-lggray p-4 border rounded-md">
            <span className="text-black font-bold">ISSUING TOKENS</span>: <p>In order to deploy and destroy tokens, the wallet address above must stay funded.</p>
            <p>This is the wallet that Solana creates for your entity upon sign up, and is used to fund any on-chain that you make through Solana.</p>
            <p>If you have any questions or concerns regarding your wallet and its contents, you can contact us at info@furlough.com</p> 
          </p>
        </div>
      </div>
    </section>
    */}

    </>
  );
};
