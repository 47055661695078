
// FtVoteBlock.tsx
import React, { useEffect, useRef } from 'react';
import { ProgressBar } from 'react-bootstrap';
import Swiper from 'swiper';
import { register } from 'swiper/element/bundle';
import 'swiper/css/bundle'; // Импортируйте стили Swiper
import './FtVoteBlock.css';


interface FtVoteBlockProps {
    title: string;
    color: string;
    votesFor: number;
    totalVotes: number;
    images: { src: string }[];
}

const FtVoteBlock: React.FC<FtVoteBlockProps> = ({ title, color, votesFor, totalVotes, images }) => {

    const imagesPerSlide = 15;
    const minSlides = 1; // if images.length == 0
    const totalSlides = Math.max(minSlides, Math.ceil(images.length / imagesPerSlide));


    const slides = Array.from({ length: totalSlides }, (_, index) => {
        const startImageIndex = index * imagesPerSlide;
        const endImageIndex = Math.min(startImageIndex + imagesPerSlide, images.length);
        const slideImages = images.slice(startImageIndex, endImageIndex);

        return (
            <div key={index} className="swiper-slide ft-vote-block--swiper-item">
                <div className={'ft-vote-block--swiper-item--grid'}>
                    {[...Array(imagesPerSlide)].map((_, imageIndex) => {
                        const currentImageIndex = startImageIndex + imageIndex;
                        const image = slideImages[imageIndex];

                        return (
                            <div key={currentImageIndex} className="ft-vote-block--swiper-item--image-container">
                                {image && <img src={image.src} alt={`Vote Image ${currentImageIndex + 1}`} />}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    });



    const swiperRef = useRef(null);

    useEffect(() => {
        // Register Swiper web component
        register();

        const swiper = new Swiper(swiperRef.current, {
            slidesPerView: 1,
            spaceBetween: 12,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '"></span>';
                },
            },
        });


        return () => {
            swiper.destroy();
        };
    }, []);



    return (
        <div className="ft-vote-block" data-color={color}>
            <div className={'ft-bg'} />
            <div className="ft-vote-block--header">
                <div className="flex justify-between">
                    <p className="ft-vote-block--header--label" data-color={color}>{title}</p>
                    <p className="ft-vote-block--header--count">{votesFor}</p>
                </div>
                <ProgressBar variant={color} now={(votesFor / totalVotes) * 100} className="ft-vote-block--progress-bar" />
            </div>
            <div className="ft-vote-block--content">
                {/* Swiper with images */}
                <div className="swiper-container" ref={swiperRef}>
                    <div className="swiper-wrapper">
                        {slides}
                    </div>

                    <div className="swiper-pagination"></div>

                    <div className="ft-swiper-buttons">
                        <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div>
                    </div>
                </div>


            </div>
            <div className="ft-vote-block--footer">{/* Дополнительный контент в подвале, если необходимо */}</div>
        </div>
    );
};

export default FtVoteBlock;
