import TokenGrid from "./token_grid";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { generalSchema } from "../../utils/schemas";
import { GeneralFormValues } from "../../types/models";
import {SaButton} from "../../components/common/saButton/saButton";
import React from "react";


export const Tokenlisting = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<GeneralFormValues>({
    resolver: zodResolver(generalSchema),
  });

  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <>
      <section className={'section ft-token-list'} data-name={'section'} data-section={'section-token-list'}>
        {/*
        <div className={'container section-title text-left'} >
          <div className="section-before-title">All Proposals</div>
          <h1  className={'section-title--h1'} >
            <span>FURLOUGH </span> <span className={'ft-text-gradient--proposals-1'}>DAO GOVERNANCE</span>
          </h1>
          <div  className={'section-subtitle'} >
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </span>
          </div>
        </div>
        */}

        <div className={'container section-content'}>

          <div className="ft-special-header">
            <h2 className="ft-special-header--title"><span className={'ft-text-gradient--special-1'}>FURLOUGH'S</span> TOKENS</h2>
            <SaButton link={`/admin/newToken`} title={`CREATE TOKEN`}  ariaLabel={`Create Token`} />
          </div>


          <div className="ft-special-content">
            <TokenGrid />
          </div>
        </div>
      </section>


{/*
      <section className="faucet__list">
        <div className="container">
          <div className=" text-white ">
            <h5 className="text-white">All Proposals</h5>
            <p className="text-[25px] mt-6 font-bold text-white">
              Furlough's Token
            </p>
            <p className="mt-6 text-justify text-[20px] bg-gray text-lggray p-4 border rounded-md  ">
            <span className="text-black font-bold">Tip</span>: Add one or more
            transactions and describe your proposal for the community. The
            proposal cannot modified after submission, so please verify all
            information before submitting. The voting period will begin after 2
            1/3 days and last for 3 days.
          </p>
          </div>

          <div className="  px-9 py-5 border rounded-md mt-[50px] ">
            <div className="flex justify-between align-middle mb-3">
              <p className="text-[25px]  font-bold text-white">
                Furlough's Tokens
              </p>
              <Link
                  to="/admin/newToken"
                  className="bg-none text-[#75f3d5] hover:no-underline"
              >
                + Create
              </Link>
            </div>
            <TokenGrid />
          </div>


        </div>
      </section>
      */}
    </>


  );
};
