import React from "react";
import "./style.css";
import TextField from "@mui/material/TextField";
import { FormControl } from "@mui/material";
import { InputField } from "../../components/CutomUiFields";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { generalSchema } from "../../utils/schemas";


type FormValues = {
  proposalTitle: string;
  summary: string;
  methodology: string;
  conclusion: string;
};

export const Createproposal = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(generalSchema),
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div>
      <section className={'section'} data-name={'section'} data-section={'section-create-proposals'}>
        <div className={'container section-title text-left'} >
          <div className="section-before-title">All Proposals</div>
          <h1  className={'section-title--h1'} >
            <span>CREATE</span> <span className={'ft-text-gradient--create-proposals-1'}>PROPOSAL</span>
          </h1>
          <div className={'section-subtitle section-subtitle-custom'} >
            <div className={'section-subtitle--tip-icon'}>
              <img src={require('./assets/icon-lamp.svg').default} alt="tip"/>
            </div>
            <div className={'section-subtitle--tip'}><strong>Tip:</strong> Add one or more transactions and describe your proposal for the community. The proposal cannot modified after submission, so please verify all information before submitting. The voting period will begin after 2 1/3 days and last for 3 days.</div>
          </div>
        </div>

        <div className={'container container-form section-container-form'}>

          <div className="ft-form-container">
            <div className="ft-bg"></div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="ft-form">
                <FormControl fullWidth className="mb-4">
                  <InputField
                      fullWidth
                      id="standard-basic"
                      label="Proposal Title"
                      variant="standard"
                      {...register("proposalTitle")}
                      error={!!errors.proposalTitle}
                      helperText={errors.proposalTitle?.message}
                  />
                </FormControl>
                <FormControl fullWidth className="mb-4">
                  <InputField
                      fullWidth
                      id="standard-basic"
                      label="Summary"
                      variant="standard"
                      {...register("summary")}
                      error={!!errors.summary}
                      helperText={errors.summary?.message}
                  />
                </FormControl>

                <FormControl fullWidth className="mb-4">
                  <InputField
                      fullWidth
                      id="standard-basic"
                      label="Methodology"
                      variant="standard"
                      {...register("methodology")}
                      error={!!errors.methodology}
                      helperText={errors.methodology?.message}
                  />
                </FormControl>

                <FormControl fullWidth className="mb-4">
                  <InputField
                      fullWidth
                      id="standard-basic"
                      label="Conclusion"
                      variant="standard"
                      {...register("conclusion")}
                      error={!!errors.conclusion}
                      helperText={errors.conclusion?.message}
                  />
                </FormControl>
                <br />
                <div className="">
                  <button className="ft-button w-full" data-component-style="1">
                    SUBMIT
                  </button>
                </div>
              </div>
            </form>
          </div>




        </div>

        <div className={'container section-content  section-content-custom-2'}>
          <h2  className={'section-title--h1'} >
            <span>DESCRIPTION</span>
          </h2>
          <div  className={'section-subtitle'} >
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
            <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
        </div>

      </section>


      {/*
      <section className="create_proposal">
      <div className="container">
        <div className="grid grid-cols-1  text-white ">
          <h5 className="text-white">All Proposals</h5>
          <p className="text-[25px] mt-6 font-bold text-white">
            Create Proposal
          </p>
          <p className="mt-6 text-justify text-[20px] bg-gray text-lggray p-4 border rounded-md  ">
            <span className="text-black font-bold">Tip</span>: Add one or more
            transactions and describe your proposal for the community. The
            proposal cannot modified after submission, so please verify all
            information before submitting. The voting period will begin after 2
            1/3 days and last for 3 days.
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-9 py-5 border rounded-md  mt-[50px] text-white">
            <FormControl fullWidth className="mb-4">
              <InputField
                fullWidth
                id="standard-basic"
                label="Proposal Title"
                variant="standard"
                {...register("proposalTitle")}
                error={!!errors.proposalTitle}
                helperText={errors.proposalTitle?.message}
              />
            </FormControl>
            <FormControl fullWidth className="mb-4">
              <InputField
                fullWidth
                id="standard-basic"
                label="Summary"
                variant="standard"
                {...register("summary")}
                error={!!errors.summary}
                helperText={errors.summary?.message}
              />
            </FormControl>

            <FormControl fullWidth className="mb-4">
              <InputField
                fullWidth
                id="standard-basic"
                label="Methodology"
                variant="standard"
                {...register("methodology")}
                error={!!errors.methodology}
                helperText={errors.methodology?.message}
              />
            </FormControl>

            <FormControl fullWidth className="mb-4">
              <InputField
                fullWidth
                id="standard-basic"
                label="Conclusion"
                variant="standard"
                {...register("conclusion")}
                error={!!errors.conclusion}
                helperText={errors.conclusion?.message}
              />
            </FormControl>
            <br />
          </div>
          <div className="grid grid-cols-1  ml-[20px] mr-[30px]   lg:grid-cols-1 lg:mx-[150px] ">
            <button className="  mt-4  px-3 py-2  bg-btnbg border rounded lg:rounded-3xl  text-[18px] lg:text-[18px]	 fw-400">
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    </section>
      */}
    </div>
  );
};
