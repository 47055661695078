import React, { useEffect, useState, useContext } from "react";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { HiQrcode } from "react-icons/hi";
import { Dialog } from "@mui/material";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { TailSpin } from "react-loader-spinner";
import { tokenContext } from "../../context/TokenContext";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    /*backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,*/
  },
  [`&.${tableCellClasses.body}`]: {
    /*fontSize: 14,
    color: theme.palette.common.white,*/
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    /*backgroundColor: "rgba(33, 35, 66, 1)",*/
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function FaucetGrid() {
  const navigate = useNavigate();
  const { setTokenAddress } = useContext(tokenContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [QRCodeValue, setQRCodeValue] = useState("");
  const [token, setToken] = React.useState();
  const [rows, setRows] = useState<any[]>([]);
  const [isFaucet, setIsFaucet] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [isCopied, setIsCopied] = useState(-1);
  const [deleteId, setDeleteId] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);

  const getTokenList = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          authorization: process.env.REACT_APP_Token as string,
        },
      };
      const res = await fetch(
        `${process.env.REACT_APP_URL}/api/allCollections`,
        requestOptions
      );
      const { data, statusCode } = await res.json();
      if (statusCode == 200) {
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        console.log("REACT_APP_PORTAL_URL", process.env.REACT_APP_URL);
        const requestOptions = {
          method: "POST",
          headers: {
            authorization: process.env.REACT_APP_Token as string,
          },
        };
        const res = await fetch(
          `${process.env.REACT_APP_URL}/api/allFaucets`,
          requestOptions
        );
        const { data, statusCode } = await res.json();
        if (statusCode == 200) {
          //console.log(data);
          if (data.length > 0) {
            setIsFaucet(true);
            const tokenList = await getTokenList();
            const normalizedFaucets = data.map((faucet) => {
              return {
                ...faucet,
                tokenName: tokenList.find(
                  (token) => token.nftaddress === faucet.collectionaddress
                ).title,
              };
            });
            setRows(normalizedFaucets);
          }
          else {
            setIsFaucet(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [reRender]);

  const onCopyClick = (index, value) => {
    console.log(process.env.REACT_APP_URL);
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_URL}/mintSBToken/${value._id}/${value.collectionaddress}`
    );

    setTokenAddress(value.collectionaddress);
    setIsCopied(index);
    //navigate("/mintSBToken");
  };

  const generateQrCode = (value) => {
    setQRCodeValue(`${process.env.REACT_APP_URL}/mintSBToken/${value._id}/${value.collectionaddress}`);
    setTokenAddress(value.collectionaddress);
    setIsModalOpen(true);
  };

  const download = function (href, name) {
    var link = document.createElement("a");
    link.download = name;
    link.style.opacity = "0";
    document.body.append(link);
    link.href = href;
    link.click();
    link.remove();
  };

  const onQRCodeDownload = () => {
    var svgElement = document.getElementById("QRCode");
    // @ts-ignore
    const width = 256;
    const height = 256;
    if (svgElement) {
      let clonedSvgElement = svgElement.cloneNode(true) as HTMLElement;
      let outerHTML = clonedSvgElement.outerHTML;
      const blob = new Blob([outerHTML], {
        type: "image/svg+xml;charset=utf-8",
      });
      let blobURL = URL.createObjectURL(blob);
      let image = new Image();
      image.src = blobURL;
      image.onload = () => {
        let canvas = document.createElement("canvas");

        canvas.width = width;

        canvas.height = height;
        let context = canvas.getContext("2d");
        context!.drawImage(image, 0, 0, width, height);
        let png = canvas.toDataURL();
        download(png, "QRCode.png");
      };
    }
  };

  const handleChange = (event) => {
    setToken(event.target.value);
  };

  const handleDelete = async (id) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        authorization: process.env.REACT_APP_Token as string,
      },
      body: new URLSearchParams(Object.entries({ faucet_id: id })).toString(),
    };
    setDeleteLoading(true);
    const res = await fetch(
      `${process.env.REACT_APP_URL}/api/deleteFaucet`,
      requestOptions
    );
    const data = await res.json();
    if (data?.statusCode == 200) {
      setReRender((prev) => !prev);
      setDeleteId("");
      setDeleteLoading(false);
      toast("Faucet Deleted");
    }
  };



  return (
    <>

      {isFaucet ? (<TableContainer
        component={Paper}
        className="overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#212342] ft-table-container"
        data-table-name={`faucet-grid`}
      >{rows.length ? (
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>FAUCET NAME</StyledTableCell>
              <StyledTableCell align="left">STATUS</StyledTableCell>
              <StyledTableCell align="left">MINTED</StyledTableCell>
              <StyledTableCell align="left">TOKEN</StyledTableCell>
              <StyledTableCell align="left">LINK</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row">
                  <p className="flex items-center gap-2 capitalize">
                    {/*
                      {row.type === "qr" ? <HiQrcode /> : <AiOutlineLink />}
                    */}

                    {
                      row.type === "qr" ? <HiQrcode />
                        :
                      <img src={require(`./assets/icon-link.svg`).default} alt={''} />
                    }
                    <span>{row.title}</span>
                  </p>
                </StyledTableCell>
                <StyledTableCell align="left">

                  {/*
                    {row?.isActive ? (
                      <Badge pill bg="info">
                        Active
                      </Badge>
                    ) : (
                      <Badge pill bg="danger">
                        Inactive
                      </Badge>
                    )}
                  */}

                  <span className={`ft-faucet-grid--item--btn ${row?.isActive ? ' ft-faucet-grid--item--btn--active' : ' ft-faucet-grid--item--btn--inactive'}`}>
                    {row?.isActive ? "Active" : "Inactive"}
                  </span>

                </StyledTableCell>
                <StyledTableCell align="left">
                  {row?.totalMinted}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <span className="capitalize">{row.tokenName}</span>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row?.type === "url" ? (
                    <button
                      className="ft-faucet-grid--item--copy-btn"
                      onClick={() => onCopyClick(i, row)}
                    >
                      <img src={require(`./assets/icon-copy.svg`).default} alt={''} />
                      {/* <BsClipboard /> */}
                      <span>{isCopied === i ? "Copied" : "Copy url"}</span>
                    </button>
                  ) : (
                    <button
                      onClick={() => generateQrCode(row)}
                      className="ft-faucet-grid--item--qr-btn"
                    >
                      <img src={require(`./assets/icon-copy.svg`).default} alt={''} />
                      <span>QR Codes</span>
                    </button>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <button
                    className="ft-faucet-grid--item--delete-btn"
                    onClick={() => setDeleteId(row._id)}
                  >
                    <img src={require(`./assets/icon-delete.svg`).default} alt={''} />
                    {/* <BsClipboard /> */}
                    Delete
                  </button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Loader message="Loading" />
      )}</TableContainer>) : (<Alert severity="info">There is no Faucet available. Please create one first.</Alert>)}



      <Dialog onClose={() => setIsModalOpen(false)} open={isModalOpen}>
        <div className="p-5">
          {/* <h2 className="mb-4">Copy Qr Code</h2> */}
          <p className="mb-4 text-sm">
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={QRCodeValue}
              viewBox={`0 0 256 256`}
              id="QRCode"
            />
          </p>
          <div className="space-x-2">
            <button
              className="px-3 py-2 bg-[#ef4444] text-white rounded-md "
              onClick={() => {
                setIsModalOpen(false);
                navigate("/admin/mintSBToken");
              }}
            >
              Close
            </button>
            <button
              className="px-3 py-2 bg-[#212342] text-white rounded-md"
              onClick={onQRCodeDownload}
            >
              Download
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog onClose={() => setDeleteId("")} open={Boolean(deleteId)}>
        <div className="p-5">
          <h2 className="mb-4">Are you sure to delete this Faucet?</h2>

          <div className="flex space-x-2">
            <button
              className="px-3 py-2 bg-[#212342] text-white rounded-md"
              onClick={() => setDeleteId("")}
            >
              Cancel
            </button>
            <button
              className="w-[118px] h-[40px] grid place-content-center bg-[#ef4444] text-white rounded-md   "
              onClick={() => handleDelete(deleteId)}
            >
              {deleteLoading ? (
                <TailSpin
                  height="20"
                  width="20"
                  color="#212342"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  visible={true}
                />
              ) : (
                "Yes, Delete"
              )}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
