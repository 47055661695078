import Switch from "@mui/material/Switch";
import Input from "@mui/material/Input";
import Label from "react-bootstrap/FormLabel";
import { FormControl } from "@mui/material";
import { InputField } from "../../components/CutomUiFields";
import { useForm, Controller } from "react-hook-form";
import { createFaucetTokenSchema } from "../../utils/schemas";
import { zodResolver } from "@hookform/resolvers/zod";

type FormValues = {
  tokenName: string;
  symbol: string;
  file: string;
  maxSupplyPerUser: string;
  isSoulBound: boolean;
};

export const CreateFaucetToken = () => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(createFaucetTokenSchema),
  });

  const onSubmit = (data) => {
    const formValues = { ...data, isSoulBound: watch("isSoulBound") };
    console.log(formValues);
  };

  return (
    <section className="create_token">
      <div className="container">
        <div className="grid grid-cols-1  text-white  ">
          <p className="text-[25px] mt-6 font-bold text-white">
            Create Faucet Token
          </p>
          {/*<p className="mt-6 text-justify text-[20px] bg-gray text-lggray p-4 border rounded-md  ">
            <span className="text-black font-bold">Tip</span>: Lorem Ipsum is
            simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard dummy text ever since the
            1500s, when an unknown printer took a galley of type and scrambled
            it to make a type specimen book. It has survived not only five
            centuries, but also the leap into electronic typesetting, remaining
            essentially unchanged. It was popularised in the 1960s with the
            release of Letraset sheets containing Lorem Ipsum passages, and more
            recently with desktop publishing software like Aldus PageMaker
            including versions of Lorem Ipsum
          </p>*/}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className=" px-9 py-5 border rounded-md  mt-[50px] text-white">
            <FormControl fullWidth className="mb-4">
              <InputField
                fullWidth
                id="standard-basic"
                label="Name"
                variant="standard"
                {...register("tokenName")}
                error={!!errors.tokenName}
                helperText={errors.tokenName?.message}
              />
            </FormControl>

            <FormControl fullWidth className="mb-4">
              <InputField
                fullWidth
                id="standard-basic"
                label="Symbol"
                variant="standard"
                {...register("symbol")}
                error={!!errors.symbol}
                helperText={errors.symbol?.message}
              />
            </FormControl>

            <div className="flex justify-between mb-4">
              <div>
                <p>Soulbond</p>
                <p className="text-zinc-300">
                  Make this token nontransferable upon claim
                </p>
              </div>
              <Controller
                name="isSoulBound"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Switch id="isSoulBound" {...field} value={false} />
                )}
              />
            </div>

            <div className="mb-4">
              <Label className="block">Upload New Image</Label>
              <Input
                type="file"
                {...register("file", {
                  required: "The File Field is Required",
                })}
              />
              {errors.file && (
                <p style={{ color: "red", fontSize: "12px" }} className="mt-1">
                  {errors.file.message}
                </p>
              )}
            </div>

            <FormControl fullWidth className="mb-4">
              <InputField
                fullWidth
                id="standard-basic"
                label="Max Supply Per User"
                variant="standard"
                {...register("maxSupplyPerUser")}
                error={!!errors.maxSupplyPerUser}
                helperText={errors.maxSupplyPerUser?.message}
              />
            </FormControl>
          </div>
          <div className="grid grid-cols-1  ml-[20px] mr-[30px]   lg:grid-cols-1 lg:mx-[150px] ">
            <button className="  mt-4  px-3 py-2  bg-btnbg border rounded lg:rounded-3xl  text-[18px] lg:text-[18px]	 fw-400">
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};
