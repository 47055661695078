import ProgressBar from "react-bootstrap/ProgressBar";
import './style.css';
import {SaButton} from "../../components/common/saButton/saButton";
import FtVoteBlock from "../../components/common/FtVoteBlock/FtVoteBlock";
import FtBlockInfo from "../../components/common/FtBlockInfo/FtBlockInfo";

export const Vote = () => {


  return (
    <div>


      <section className={'section'} data-name={'section'} data-section={'section-vote'}>
        <div className={'container section-title text-left'} >
          <div className="section-before-title">All Proposals</div>
          <h1  className={'section-title--h1'} >
            <span>Proposal 1</span>
          </h1>
          <div  className={'section-subtitle'} >
            <span>
              {" "}
                  Voting ends approximately December 5, 2021 7:10 AM GMT+2. A total of
              12 votes are required to reach quorum
            </span>
          </div>
        </div>


        <div className={'container section-content'}>

          <div className="ft-vote-header">
            <div className="ft-vote-header--message">Connect a wallet to vote.</div>
            <SaButton link={`#`} title={`SUBMIT VOTE`}  ariaLabel={`Submit vote`} isEnabled={true} />
            {/* <a href="#" class="ft-button " target="" data-name="ftButton" data-component-style="1" aria-label="Submit vote">SUBMIT VOTE</a> */}
          </div>


          <div className="ft-vote-content">

            {/*
              title
              color
              votes for this block
              votes in total
              array images like this [{'src':''},{'src':''}]
            */}


            <div className="ft-vote-blocks">

                <FtVoteBlock
                    title={'FOR'}
                    color={'green'}
                    votesFor={20}
                    totalVotes={25}
                    images={
                      [
                        { src: require('./assets/test-avatar-1.png') },
                        { src: require('./assets/test-avatar-2.png') },
                        { src: require('./assets/test-avatar-3.png') },
                        { src: require('./assets/test-avatar-4.png') },
                        { src: require('./assets/test-avatar-1.png') },
                        { src: require('./assets/test-avatar-2.png') },
                        { src: require('./assets/test-avatar-3.png') },
                        { src: require('./assets/test-avatar-4.png') },
                        { src: require('./assets/test-avatar-1.png') },
                        { src: require('./assets/test-avatar-2.png') },
                        { src: require('./assets/test-avatar-3.png') },
                        { src: require('./assets/test-avatar-4.png') },
                        { src: require('./assets/test-avatar-1.png') },
                        { src: require('./assets/test-avatar-2.png') },
                        { src: require('./assets/test-avatar-3.png') },
                        { src: require('./assets/test-avatar-4.png') },
                        { src: require('./assets/test-avatar-1.png') },
                        { src: require('./assets/test-avatar-2.png') },
                        { src: require('./assets/test-avatar-3.png') },
                        { src: require('./assets/test-avatar-4.png') }
                      ]
                    }
                />


                <FtVoteBlock
                    title={'AGAINST'}
                    color={'pink'}
                    votesFor={3}
                    totalVotes={25}
                    images={
                      [
                        { src: require('./assets/test-avatar-1.png') },
                        { src: require('./assets/test-avatar-2.png') },
                        { src: require('./assets/test-avatar-3.png') }
                      ]
                    }
                />



                <FtVoteBlock
                    title={'ABSTAIN'}
                    color={'grey'}
                    votesFor={2}
                    totalVotes={25}
                    images={
                      [
                        { src: require('./assets/test-avatar-1.png') },
                        { src: require('./assets/test-avatar-2.png') }
                      ]
                    }
                />


            </div>



            <div className="ft-vote-blocks-informations">

                <FtBlockInfo
                    title={'THRESHOLD'}
                    label={'Current Threshold'}
                    value={'56 Votes'}
                    icon={'search'}
                />

                <FtBlockInfo
                    title={'ENDS'}
                    label={'2:55 AM GMT +2'}
                    value={'November  26, 2023'}
                />

                <FtBlockInfo
                    title={'SNAPSHOT'}
                    label={'Taken at block'}
                    value={'18906789'}
                />

            </div>


          </div>

        </div>


        <div className={'container section-content  section-content--vote-description'}>
          <h2  className={'section-title--h1'} >
            <span>DESCRIPTION</span>
          </h2>
          <div  className={'section-subtitle'} >
            <p><strong>Nouns Proposal Auction House</strong></p>
            <p>Nouns Proposal Auction House (or Prop House) is an experimental approach to deploying capital: an auction of ETH where the bids placed are proxy NounsDAO proposals. At the end of each auction, Noun owners (Nouners) vote on which proposal gets funded.</p>
            <p>This proposal aims to be the MVP for a trustless on-chain solution.</p>
          </div>
        </div>

      </section>




{/*
      <div className="grid grid-cols-1 pl-[18px] pr-[30px] lg:px-[150px] ">
        <h5 className="text-white">All Proposals</h5>
        <p className="text-[25px] mt-6 font-bold text-white">Proposal 1</p>
        <p className="mt-6 text-justify text-[15px]  text-white">
          {" "}
          Voting ends approximately December 5, 2021 7:10 AM GMT+2. A total of
          12 votes are required to reach quorum
        </p>
      </div>

      <div className="grid grid-cols-1  gap-10 lg:grid-cols-3 pl-[20px] pr-[33px] lg:px-[150px] mt-[30px]">

        <div className="bg-heading border rounded-md pt-4 pb-3 px-2">
          <div className="flex justify-between">
            <p className="text-dark">For</p>
            <p className="text-dark">10</p>
          </div>
          <ProgressBar variant="green" now={80} className="mt-4 " />
        </div>
        <div className="bg-heading border rounded-md pt-4 pb-3 px-2">
          <div className="flex justify-between">
            <p className="text-dark">Against</p>
            <p className="text-dark">2</p>
          </div>
          <ProgressBar variant="purple" now={50} className="mt-4 " />
        </div>
        <div className="bg-heading border rounded-md pt-4 pb-3 px-2">
          <div className="flex justify-between">
            <p className="text-dark">Abstain</p>
            <p className="text-dark">4</p>
          </div>
          <ProgressBar variant="pink" now={20} className="mt-4 " />
        </div>{" "}
      </div>

      <div className="grid grid-cols-1 pl-[18px] pr-[33px] lg:px-[150px] mt-[40px] ">
        <h5 className="text-3xl text-white">Description</h5>
        <p className="text-[30px] mt-6 font-bold text-white">
          Nouns Proposal Auction House
        </p>
        <p className="mt-6 text-justify text-2xl text-white ">
          {" "}
          Nouns Proposal Auction House (or Prop House) is an experimental
          approach to deploying capital: an auction of ETH where the bids placed
          are proxy NounsDAO proposals. At the end of each auction, Noun owners
          (Nouners) vote on which proposal gets funded.
        </p>

        <p className="mt-6 text-justify text-2xl text-white ">
          {" "}
          This proposal aims to be the MVP for a trustless on-chain solution.
        </p>

        <button className="  mt-4  px-3 py-2  bg-btnbg border rounded lg:rounded-3xl lg:mr-4 text-[18px] lg:text-[18px]	 fw-400">
          SUBMIT YOUR VOTE
        </button>
      </div>
*/}

    </div>
  );
}
