import React from "react";

export const IconMenu = () => {
  return (
    <svg
      width="30"
      height="16"
      viewBox="0 0 30 16"
      fill="curentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.3636 0H28.6364C29.3892 0 30 0.448047 30 1C30 1.55204 29.389 2 28.6364 2H1.3636C0.610844 2 0 1.55195 0 1C-0.000114441 0.447963 0.610844 0 1.3636 0Z"
        fill="curentColor"
      />
      <path
        d="M8.04543 7H28.9546C29.5317 7 30 7.44805 30 8C30 8.55204 29.5316 9 28.9546 9H8.04543C7.46832 9 7 8.55195 7 8C6.99991 7.44796 7.46832 7 8.04543 7Z"
        fill="curentColor"
      />
      <path
        d="M15.6818 14H29.3182C29.6946 14 30 14.448 30 15C30 15.552 29.6945 16 29.3182 16H15.6818C15.3054 16 15 15.552 15 15C14.9999 14.448 15.3054 14 15.6818 14Z"
        fill="curentColor"
      />
    </svg>
  );
};
