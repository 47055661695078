import "./style.css";
import { Auction } from "../../components/auction";
import { Mint } from "../../components/mint";
import React, {useState} from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import {SaButton} from "../../components/common/saButton/saButton";
import {FtGridImgText} from "../../components/common/FtGridImgText/FtGridImgText";
import FtRewards from "../../components/common/FtRewards/FtRewards";
import {FtAccordionItem} from "../../components/common/FtAccordionItem/FtAccordionItem";

export const Homepage = () => {
  const { connection } = useConnection();
  const wallet = useWallet();
  // debugger;
  const [Summary, setSummary] = useState(false);
  const [Daily, setDaily] = useState(false);

  const [Furlogh, SetFurlogh] = useState(false);

  const [Community, SetCommunity] = useState(false);
  const [Rewards, SetRewards] = useState(false);


  const rewardsData = [
    { id: 1, quantity: 1, isActive: false },
    { id: 2, quantity: 2, isActive: false },
    { id: 3, quantity: 3, isActive: true },
    { id: 4, quantity: 4, isActive: false },
    { id: 5, quantity: 5, isActive: false },
    { id: 6, quantity: 6, isActive: false },
    { id: 7, quantity: 7, isActive: false },
  ];

  return (

    <div>


        <section className={'section'} data-name={'section'} data-section={'ft-grid-home-img-text'}>
            <div className="container relative">
                <Mint></Mint>
            </div>
        </section>

      


      {/*
      <div className="grid grid-cols-1 lg:grid-cols-1 relative  mb-[20px]  mt-[40px] lg:mt-0 ">
        <div className=" block lg:flex ">
          <div className=" mt-[40px] mb-[40px] flex justify-center lg:block lg:mt-[170px]  ">
            <h1 className=" text-[26px] text-white lg:text-[90px] font-bold px-[5px] lg:px-[90px] lg:text-left">
              EVERY
            </h1>
            <h1 className=" text-[26px] text-white lg:text-[90px] font-bold  px-[5px] lg:px-[90px] lg:text-left">
              VOTE
            </h1>
            <h1 className=" text-[26px] text-white lg:text-[90px] font-bold px-[5px] lg:px-[90px] lg:text-left">
              COUNTS
            </h1>
          </div>
          <div className=" flex justify-start items-center pl-[20px] pr-[30px] lg:pr-[100px] lg:mt-[150px]   ">
            <img src="./minimap.png" alt="" className=" " />
          </div>
        </div>
      </div>
      */}



<section className={'section'} data-name={'section'} data-section={'section-home-rewards'}>
        <div className={'container section-title text-center'} >
          <h1 className={'section-title--h1'} >
            <span>DAILY LOGIN</span> <span className={'ft-text-gradient--home-1'}>REWARDS</span>
          </h1>
          <div className={'section-subtitle'} >
            <span>Engage with our community in Discord to earn rewards each day and climb up the leaderboard.</span>
          </div>
        </div>
        <div className={'container section-content'}>
          <div>
            <FtRewards rewards={rewardsData} />
          </div>

          <div className={`mt-[40px] text-center`}>
            <SaButton link={'#'} title={'CLAIM'} isEnabled={true} />
            {/*<a href="#" className="ft-button " target="" data-name="ftButton" data-component-style="1" aria-label="">CLAIM</a>*/}
          </div>

        </div>
      </section>
      <section className={'section'} data-name={'section'} data-section={'ft-grid-home-img-text'}>
      <FtGridImgText
            title="THE NEXT ERA OF COLLABORATION"
            content="Phigital is a community-owned brand that empowers professionals online by funding ideas and fostering collaboration. From entrepreneurs and marketers, to SaaS and DTC brands. Phigital is space for professionals to grow."
            imageSrc={require('./assets/home-img-grid-1.png')}
        />
      <FtGridImgText
            title="ENGAGE AND GET REWARDED"
            content="The more you play the more you earn. Connect, learn, engage and grow with our community to build your reputation on chain. Our goal is to transform your wallet into a resume showcasing your skills and bringing you new opportunities."
            imageSrc={require('./assets/home-img-grid-3.png')}
        />
        </section>
        <section className={'section'} data-name={'section'} data-section={'ft-grid-home-img-text'}>
        <div className="container relative ft-grid-nft">
          <Auction></Auction>
          {/*<div className="flex justify-center text-white ">
            <img src="/frame.png" alt="" />
          </div>

          <div className="mt-[50px] lg:mt-0">
            <p className="text-white" >Dec 01 2021</p>
            <div className="flex items-center">
              <p className="text-[27px]  mt-4 text-white">Proposal #1</p>
              <BsArrowRight size={20} className="mt-4 ml-4 mr-2" />
              <BsArrowLeft size={20} className="mt-4 " />
            </div>

            <div className="flex items-center mt-2 text-white">
              <div className="border-r-[1px] p-4">
                <p className="text-[16px]">Current bid</p>
                <p className="text-[24px]">31.41</p>
              </div>
              <div className="p-4">
                <p className="text-[16px]">Ends in</p>
                <p className="text-[24px]">11h 10m 40s</p>
              </div>
            </div>

            <div className="flex  flex-col  lg:flex-row  lg:items-center mt-4">
              <div className=" p-1">
                <p className="text-[14px] text-white">Minimum bid: 0.4 SOL</p>
                <input
                  className="bg-white inputcenter lg:inputright border py-[17px]  w-[100%] rounded-md text-[#000000]"
                  style={{ color: "black" }}
                  type="number"
                  placeholder="SOL"
                />
              </div>
              <div className="p-1 mt-3 border-black">
                <button className="bg-black inputcenter1   rounded-md w-[100%] mt-1  py-[19px] px-[30px]">
                  BID
                </button>
              </div>
            </div>
            <TopBidList></TopBidList>
          </div>*/}
        </div>
      </section>
      <section className={'section'} data-name={'section'} data-section={'ft-grid-home-img-text'}>
        <FtGridImgText
            title="EVERY VOTE COUNTS"
            content="One Phi, Every Day, Forever. Phigital is a DAO from the Furlough community which auctions daily a chance to become an owner in the organization. Each day one Phi is auctioned off that is equivalent to one Vote. A Phi is able to submit proposals for initiatives funded by the treasury."
            imageSrc={require('./assets/home-img-grid-2.png')}
            isLeftAligned
        />
      </section>
{/*
      <div className="mt-4 lg:mt-0">
        <div className="grid  grid-cols-1 lg:grid-cols-1 pl-[30px] pr-[30px] lg:px-[40px] lg:py-[100px] ">
          <div className="flex flex-wrap pr-[20px] lg:pr-[0px] ">
            <div className="bg-boxclr  w-[100%] h-[200px] lg:h-[200px] lg:mx-[15px] mb-[20px] lg:w-[180px]  text-boxclr">
              3
            </div>
            <div className="bg-boxclr  w-[100%] h-[200px] lg:h-[200px] lg:mx-[15px] mb-[20px] lg:w-[180px] text-boxclr">
              3
            </div>
            <div className="bg-boxclr  w-[100%] h-[200px] lg:h-[200px] lg:mx-[15px] mb-[20px] lg:w-[180px]  text-boxclr">
              3
            </div>

            <div className="bg-boxclr  w-[100%] h-[200px] lg:h-[200px] lg:mx-[15px] mb-[20px] lg:w-[180px]  text-boxclr">
              3
            </div>
            <div className="bg-boxclr  w-[100%] h-[200px] lg:h-[200px] lg:mx-[15px] mb-[20px] lg:w-[180px]  text-boxclr">
              3
            </div>
            <div className="bg-boxclr  w-[100%] h-[200px] lg:h-[200px] lg:mx-[15px] mb-[20px] lg:w-[180px]  text-boxclr">
              3
            </div>
          </div>
        </div>
      </div>

      */}



      <section className={'section'} data-name={'section'} data-section={'section-faq'}>
        <div className={'container section-title text-center'} >
          <h1  className={'section-title--h1'} >
            <span>Phigital</span> <span className={'ft-text-gradient--home-2'}>DAO</span>
          </h1>
          <div  className={'section-subtitle'} >
            {/*<p>
              Nouns are an experimental attempt to improve the formation of on-chain avatar communities. While projects such as CryptoPunks have attempted to bootstrap digital community and identity, Nouns attempt to bootstrap identity, community, governance, and a treasury that can be used by the community.
            </p>
            <p>
              Learn more below, or start creating Nouns off-chain using the Playground.
            </p>*/}
            <p>Phigital DAO is a product of the Furlough Community. A way to tokenize the ecosystem of collaboration and give ownership through decentralization to our most engaged members. The Phigital DAO goal is to gamify business and create an identity layer for professionals online.</p>
          </div>
        </div>

        <div className={'container section-content'} >

          <div className={'ft-home-faq'}>

            <FtAccordionItem
                title={'Summary'}
                content={<ul>
                          <li>Furlough artwork is in the public domain to all DAO holders.</li>
                          <li>One Phi is trustlessly auctioned every 24 hours, forever.</li>
                          <li>95% of Phi auction proceeds are trustlessly sent to the treasury.</li>
                          <li>Settlement of one auction kicks off the next.</li>
                          <li>All Phi are members of PHI DAO.</li>
                          <li>One PHI is equal to one vote.</li>
                          <li>The treasury is controlled exclusively by PHI via governance.</li>
                          <li>No explicit rules exist for attribute scarcity; all PHI auctions are equally rare.</li>
                          <li>Phounders receive rewards in the form of PHI (10% of supply for first 5 years)</li>
                          </ul>
                        }
            />

            <FtAccordionItem
                title={'Daily Auctions'}
                content={
                  <>
                  <p>The Phigital Auction Contract will act as a self-sufficient Phi generation and distribution mechanism, auctioning one Phi every 24 hours, forever. 100% of auction proceeds (USDC) are automatically deposited in the Phigital DAO treasury, where they are governed by Phi owners.</p>
                  <p>Each time an auction is settled, the settlement transaction will also cause a new Phi to be minted and a new 24 hour auction to begin.</p>  
                  <p>While settlement is most heavily incentivized for the winning bidder, it can be triggered by anyone, allowing the system to trustlessly auction Phi as long as Solana is operational and there are interested bidders</p>  
                    {/*<p>The Nouns Auction Contract will act as a self-sufficient Noun generation and distribution mechanism, auctioning one Noun every 24 hours, forever. 100% of auction proceeds (ETH) are automatically deposited in the Nouns DAO treasury, where they are governed by Noun owners.
                    </p>
                    <p>Each time an auction is settled, the settlement transaction will also cause a new Noun to be minted and a new 24 hour auction to begin.</p>
                    <p>While settlement is most heavily incentivized for the winning bidder, it can be triggered by anyone, allowing the system to trustlessly auction Nouns as long as Ethereum is operational and there are interested bidders.</p>*/}
                  </>
                }
            />

            <FtAccordionItem
                title={'FURLOUGH DAO'}
                content={`Nouns DAO utilizes a fork of Compound Governance and is the main governing body of the Nouns ecosystem. The Nouns DAO treasury receives 100% of ETH proceeds from daily Noun auctions. Each Noun is an irrevocable member of Nouns DAO and entitled to one vote in all governance matters. Noun votes are non-transferable (if you sell your Noun the vote goes with it) but delegatable, which means you can assign your vote to someone else as long as you own your Noun.`}
            />
              {/*
                            <FtAccordionItem
                                title={'Community Tokens'}
                                content={`Furlogh is great to Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                  dolore magna aliqua.`}
                            />
                */}
            <FtAccordionItem
                title={`Founder's Rewards`}
                content={
                    <>
                    <p>'Phounders' are the group of builders that initiated Phi. These ‘Phounders’ are the Founders of the Furlough community and all it’s equity partners.</p>

                    {/*<p><strong>{'<Phounder Names>'}</strong></p>*/}
                    <p>Because 100% of Phi auction proceeds are sent to Phigital DAO, Phounders have chosen to compensate themselves with Phi. Every 10th Phi for the first 5 years of the project (Phi ids #0, #10, #20, #30 and so on) will be automatically sent to the Phounder’s multisig to be vested and shared among the founding members of the project.</p>

                    <p>Phounder distributions don't interfere with the cadence of 24 hour auctions. Phi are sent directly to the Phounder’s Multisig, and auctions continue on schedule with the next available Phi ID.</p>

                    </>
                }
            />

          </div>
        </div>
      </section>

      {/*
      <div className="grid grid-cols-1 pl-[30px] pr-[50px] lg:px-[150px]  relative">
        <p className="text-[35px] mt-6 font-bold  text-center  lg:text-left text-white">
          Furlough DAO
        </p>
        <img
          className="absolute position"
          src="./ellipse1.png"
          width="100px"
          height="200px"
          alt=""
        />

        <p className="mt-6 text-justify  text-[20px] text-white ">
          {" "}
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
          ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
        <p className="mt-6 text-justify  text-[20px] text-white">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
        </p>
        <div className="mt-[40px]">
          <button
            className="w-[100%] px-1 py-1"
            onClick={() => setSummary(!Summary)}
          >
            <div className="flex justify-between items-center mt-4">
              <p className="text-[25px] font-bold text-white">Summary</p>
              <IoIosArrowDown size={28} className="font-bold" />
            </div>
          </button>
          {Summary ? (
            <p className="mt-6 text-justify  text-[20px] text-white">
              Furlogh is great to Lorem ipsum dolor sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua.{" "}
            </p>
          ) : (
            <></>
          )}

          <button
            className="w-[100%] px-1 py-1"
            onClick={() => setDaily(!Daily)}
          >
            <div className="flex justify-between items-center mt-4">
              <p className="text-[25px] font-bold text-white">Daily Auctions</p>
              <IoIosArrowDown size={28} className="font-bold" />{" "}
            </div>
          </button>
          {Daily ? (
            <p className="mt-6 text-justify  text-[20px] text-white">
              Furlogh is great to Lorem ipsum dolor sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua.{" "}
            </p>
          ) : (
            <></>
          )}

          <button
            className="w-[100%] px-1 py-1"
            onClick={() => SetFurlogh(!Furlogh)}
          >
            <div className="flex justify-between items-center mt-4">
              <p className="text-[25px] font-bold text-white">Furlough DAO</p>
              <IoIosArrowDown size={28} className="font-bold" />{" "}
            </div>
          </button>
          {Furlogh ? (
            <p className="mt-6 text-justify  text-[20px] text-white">
              Furlogh is great to Lorem ipsum dolor sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua.{" "}
            </p>
          ) : (
            <></>
          )}
          <button
            className="w-[100%] px-1 py-1 border-none"
            onClick={() => SetCommunity(!Community)}
          >
            <div className="flex justify-between items-center mt-4">
              <p className="text-[25px] font-bold text-white">Community Tokens</p>
              <IoIosArrowDown size={28} className="font-bold" />{" "}
            </div>
          </button>

          {Community ? (
            <p className="mt-6 text-justify  text-[20px] text-white">
              Furlogh is great to Lorem ipsum dolor sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua.{" "}
            </p>
          ) : (
            <></>
          )}

          <button
            className="w-[100%] px-1 py-1"
            onClick={() => SetRewards(!Rewards)}
          >
            <div className="flex justify-between items-center mt-4">
              <p className="text-[25px] font-bold text-white">Rewards</p>
              <IoIosArrowDown size={28} className="font-bold" />{" "}
            </div>
          </button>
          {Rewards ? (
            <p className="mt-6 text-justify  text-[20px] text-white">
              Furlogh is great to Lorem ipsum dolor sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua.{" "}
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>*/}
    </div>

  );
}
