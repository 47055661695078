import React from "react";

export const LoaderboardAvatar = ({ img, username }) => {
  return (
    <div className="AVATAR flex items-center gap-[6px]">
      <div className="rounded-full w-[43px] h-[43px] overflow-hidden ">
        <img src={img} alt={username} />
      </div>
      <span className=" text-base">{username}</span>
    </div>
  );
};
