import "./style.css";
import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import image_discord_logo from './assets/discord-logo.svg';
import image_avatar from './assets/avatar.svg';
import logo_solflare from './assets/logo_solflare.svg';
import logo_phantom from './assets/logo_phantom.svg';
import logo_discord from './assets/logo_discord.svg';
import { FormControl } from "@mui/material";
import { InputField } from "../../components/CutomUiFields";
import { SaButton } from "../../components/common/saButton/saButton";
import { useWallet } from "@solana/wallet-adapter-react";


export const Profile = () => {
    const { openAuthPopup, discordLogout, discordToken,discordProfileImage,discordGlobalName,discordUserName } =
        useContext(AuthContext);
    //let wallet = useWallet();

    const [show, setShow] = useState(false);
    const [avatar, setAvatar] = useState(false);
    //let displayName="MyNickname123";
    //let global_name="My Name";
    //let discord_image=image_avatar;
    const [discord_image, setDiscordImage] = useState(image_avatar);
    const [displayName, setDisplayName] = useState("MyNickname123");
    const [global_name, setGlobalName] = useState("My Name");
    useEffect(() => {
        /*const onLoad = () => {
            checkIfDiscordConnected();
        };
        window.addEventListener("load", onLoad);
        return () => window.removeEventListener("load", onLoad);*/
        if (discordToken && discordToken !== null) {
            //console.log(discordProfile);
            debugger;
            setShow(true);
        }
        if(discordGlobalName){
            //console.log("discord profile");
            //console.log(discordProfile);
            setGlobalName(discordGlobalName);
            setDisplayName(discordUserName);
            setDiscordImage(discordProfileImage);
            setAvatar(true);
        }
    }, [discordToken,discordProfileImage,discordGlobalName,discordUserName]);
    const checkIfDiscordConnected = async () => {
        try {
            if (discordToken && discordToken !== null) {
                //debugger;
                setShow(true);
            }
        } catch (err) {
            console.log(err);
        } finally {
        }
    };

    return (
        <div id={'profile-page'} className={'profile-page'}>
            <section data-name={'section'} className={'section profile-page--section'} data-section={'section-profile'}>
                <div className={'container section-title text-left'} >
                    {/*<div className="section-before-title">All Proposals</div>*/}
                    <h1 className={'section-title--h1'} >
                        <span>PROFILE</span>
                    </h1>
                    {/*<div  className={'section-subtitle'} >
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </span>
                    </div>*/}
                </div>

                {
                    !show
                        ?
                        <div className={'container section-content'}>
                            <div className="profile-page--discord-section">
                                <div className="ft-bg"></div>
                                <div className="profile-page--discord-section--inner">

                                    <img src={image_discord_logo} alt=""
                                        className={'profile-page--discordLogo'} />

                                    <div className="profile-page--discord-section--text">
                                        To continue working with the website, you need to connect your discord account
                                    </div>

                                    <div className="profile-page--discord-section--button-container">
                                        {/*<SaButton link={``} title={`CONNECT`}  ariaLabel={`Connect Discord`}/>*/}
                                        <button  className="ft-button " data-component-style="1"
                                            onClick={discordToken ? discordLogout : openAuthPopup}
                                        >
                                            {discordToken ? "Logout" : "Login"}
                                        </button>
                                    </div>

                                </div>
                            </div>


                        </div>
                        :
                        <>
                            <div className={'container section-content'}>
                                <div className={'profile-page--info'}>
                                    <div className={'ft-bg'}></div>
                                    <div className={'profile-page--grid-info'}>
                                        <div className={'profile-page--grid-info-1'}>
                                            <div className={'profile-page--avatar'}>
                                                {
                                                    avatar
                                                        ? <img src={discord_image} alt={''} />
                                                        : <img src={image_avatar} alt={''} />
                                                }
                                            </div>
                                        </div>
                                        <div className={'profile-page--grid-info-2'}>
                                            <div className={'profile-page--form'}>

                                                <div className="ft-form">

                                                    <div className={'ft-form-half'}>
                                                        <div className="ft-form-select-container">
                                                            <FormControl fullWidth className="mb-4">
                                                                <InputField
                                                                    fullWidth
                                                                    id="profile-page--input-name"
                                                                    label="Name"
                                                                    variant="standard"
                                                                    defaultValue={global_name}
                                                                    disabled
                                                                />
                                                            </FormControl>
                                                        </div>

                                                        <div className="ft-form-select-container">
                                                            <FormControl fullWidth className="mb-4">
                                                                <InputField
                                                                    fullWidth
                                                                    id="profile-page--input-nickname"
                                                                    label="Display Name"
                                                                    variant="standard"
                                                                    defaultValue={displayName}
                                                                    disabled
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </div>


                                                    {/*<div className="ft-form-select-container">
                                                        <FormControl fullWidth className="mb-4">
                                                            <InputField
                                                                fullWidth
                                                                id="profile-page--input-email"
                                                                label="Email"
                                                                variant="standard"
                                                                defaultValue={'myemail@gmail.com'}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </div>*/}



                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={'container section-content'}>
                                <div className={'ft-special-header'}>
                                    <h2 className={'ft-special-header--title'}>SERVICES</h2>
                                </div>
                                <div className={'ft-special-content'}>
                                    <div className={'profile-page--services'}>


                                        {/*<div className="profile-page--service-item">
                                            <div className="ft-bg"></div>
                                            <div className="profile-page--service-item--inner">
                                                <div className="profile-page--service-item--logo">
                                                    <img src={logo_solflare} alt="" />
                                                </div>
                                                <div className="profile-page--service-item--content">
                                                    <div className="profile-page--service-item--title">
                                                        Solflare
                                                    </div>
                                                    <div className="profile-page--service-item--status" data-is-connected={'true'}>
                                                        connected
                                                    </div>
                                                </div>
                                            </div>
                                        </div>*/}


                                        <div className="profile-page--service-item">
                                            <div className="ft-bg"></div>
                                            <div className="profile-page--service-item--inner">
                                                <div className="profile-page--service-item--logo">
                                                    <img src={logo_phantom} alt="" />
                                                </div>
                                                <div className="profile-page--service-item--content">
                                                    <div className="profile-page--service-item--title">
                                                        Phantom
                                                    </div>
                                                    <div className="profile-page--service-item--status" data-is-connected={'true'}>
                                                        connected
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="profile-page--service-item">
                                            <div className="ft-bg"></div>
                                            <div className="profile-page--service-item--inner">
                                                <div className="profile-page--service-item--logo">
                                                    <img src={logo_discord} alt="" />
                                                </div>
                                                <div className="profile-page--service-item--content">
                                                    <div className="profile-page--service-item--title">
                                                        Discord
                                                    </div>
                                                    <div className="profile-page--service-item--status" data-is-connected={'true'}>
                                                    {discordToken ? "connected" : "not connected"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </>
                }

            </section>

        </div>
    )
}