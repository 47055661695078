
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";


export const NotificationPopUp = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Notification
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="bg-[#808080] p-2 border  rounded-md flex justify-between items-center">
          <div className="flex">

            <p className="text-white">{props.message}</p>
          </div>

        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button className="bg-blue" onClick={props.onHide}>
          Close
        </Button>

      </Modal.Footer>
    </Modal>
  );
};
