// FtGridImgText.tsx
import React from 'react';
import {SaImage} from "../saImage/saImage";
import './FtGridImgText.css';
interface FtGridImgTextProps {
    title: string;
    content: string;
    imageSrc: string;
    isLeftAligned?: boolean;
}

export const FtGridImgText: React.FC<FtGridImgTextProps> = ({ title, content, imageSrc, isLeftAligned }) => {
    const containerClass = `container ft-grid-img-text ${isLeftAligned ? 'ft-grid-img-text--left' : 'ft-grid-img-text--right'}`;
    const textClass = 'ft-grid-img-text--text';
    const imgClass = 'ft-grid-img-text--img';

    return (
        <div className={containerClass}>
            <div className={textClass}>
                <h1 className="ft-grid-img-text--title">{title}</h1>
                <div className="ft-grid-img-text--content">{content}</div>
            </div>
            <div className={imgClass}>
                <SaImage src={imageSrc} />
            </div>
        </div>
    );
};

