import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import './FtAccordionItem.css';


interface FtAccordionItemProps {
    title: string;
    content: string | React.ReactNode;
}

export const FtAccordionItem: React.FC<FtAccordionItemProps> = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={'ft-accordion-item'} data-accordion-active={isOpen}>
            <div
                className="ft-accordion-item--btn"
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="ft-accordion-item--title-row">
                    <p className="ft-accordion-item--title">{title}</p>
                    <IoIosArrowDown size={28} className={`font-bold ${isOpen ? 'rotate-180' : ''}`} />
                </div>
            </div>
            {isOpen && (
                <div className="ft-accordion-item--text">
                    { content }
                </div>
            )}
        </div>
    );
};
