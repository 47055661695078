import React, { useEffect, useState } from "react";
import { NotificationPopUp } from "../../popup/notification";

// @ts-ignore
//import { saBtn, saBtnLight, saBtnDark, arrow } from './saButton.module.scss'

interface saTypeButton {
  componentStyle?: string;
  show?: boolean;
  isEnabled?: boolean;
  classes?: string | null;
  title?: string;
  ariaLabel?: string;
  link: string;
  target?: boolean;
  arrowIsShow?: boolean;
}

export const SaButton: React.FC<saTypeButton> = (props) =>  {
  /*let saBtnClass;*/
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationModalShow, setNotificationModalShow] = useState(false);
  const show =  props.show ?? true;
  const isEnabled =  props.isEnabled ?? true;

  const componentStyle = props.componentStyle ?? 1;

  const target = props.target ?? false;
  const arrowIsShow = props.arrowIsShow ?? false;

  const redirectToLink = async (e:any) => {

  };
  if (show) {
    return (
      <div>
        <a
            href={props.link}
            className={`${'ft-button'} ${props.classes || ''}`}
            target={target ? '_blank' : ''}
            data-name={'ftButton'}
            onClick={ !isEnabled?(event) => {event.preventDefault();
              //debugger;
              setNotificationMessage("Please connect your wallet.");
              setNotificationModalShow(true);}:redirectToLink }
            data-component-style={componentStyle}
            aria-label={props.ariaLabel || ''}
        >
          { props?.title || "Click" }
          {
              arrowIsShow &&
              <svg id="kt_118823" className={'arrow'} data-name="kt_11883 17" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 18.087 8.44">
                <path id="kt_11883" data-name="kt 11883" d="M.706,135.54H15.669l-2.479-2.376a.663.663,0,0,1,0-.963.726.726,0,0,1,1,0l3.692,3.54h0a.664.664,0,0,1,0,.964h0l-3.692,3.54a.726.726,0,0,1-1,0,.663.663,0,0,1,0-.963l2.479-2.376H.706a.681.681,0,1,1,0-1.361Z" transform="translate(0 -132)" fill="#262B2F"/>
              </svg>
          }
        </a>
        <NotificationPopUp
        show={notificationModalShow}
        onHide={() => setNotificationModalShow(false)}
        message={notificationMessage}
      ></NotificationPopUp>
      </div>
    );
  } else {
    return null;
  }
};
