import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Loader from "../../components/Loader";





const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
   /* backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,*/
  },
  [`&.${tableCellClasses.body}`]: {
   /* fontSize: 14,
    color: theme.palette.common.white,*/
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    /*backgroundColor: "rgba(33, 35, 66, 1)",*/
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TokenGrid() {
  const [rows, setRows] = useState<any[]>([]);
  const [isToken, setIsToken] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        const requestOptions = {
          method: "POST",
          headers: {
            authorization: process.env.REACT_APP_Token as string,
          },
        };
        const res = await fetch(
          `${process.env.REACT_APP_URL}/api/collectionStats`,
          requestOptions
        );
        const { data, statusCode } = await res.json();
        if (statusCode == 200) {
          if (data.length > 0) {
            setIsToken(true);
            console.log(data);
            const normalizedData = await Promise.all(
              data.map(async (el) => {
                //const { symbol, image } = await fetchNftUri(el.collection.nfturi);
                return {
                  ...el.collection,
                  activeFaucet: el.activeFaucet,
                  minted: el.minted,
                  //symbol,
                  //image,
                };
              })
            );
            console.log(normalizedData);
            setRows(normalizedData);
          } else {
            setIsToken(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <TableContainer
      component={Paper}
      className=" overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#212342] ft-table-container"
      data-table-name={`token-grid`}
    >
      {rows.length ? (<Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>FAUCET NAME</StyledTableCell>
            <StyledTableCell align="left">SYMBOL</StyledTableCell>
            <StyledTableCell align="left">ACTIVE FAUCETS</StyledTableCell>
            <StyledTableCell align="left">CLAIMED</StyledTableCell>
            <StyledTableCell align="left">MAX SUPPLY</StyledTableCell>
            <StyledTableCell align="left">STATUS</StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {rows.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {/*<p className="flex items-center gap-1">*/}
                  <div className={'ft-token-grid--item-img-and-text'}>
                    <div className={'ft-token-grid--item-img'}>
                      {row.imageuri ? (
                        <img src={row.imageuri} alt="" className="" />
                      ) : (
                        <img src={require('./assets/no_image.svg').default} alt="" className="ft-no-image" />
                      )}
                    </div>
                  <div className="space-x-2">
                    <span>{row.title}</span>
                    <span className="ft-token-grid--item-text">
                      {row.currency}
                    </span>
                  </div>
                </div>
                {/*</p>*/}
              </StyledTableCell>

              <StyledTableCell align="left">{row?.symbol}</StyledTableCell>
              <StyledTableCell align="left">
                {row?.activeFaucet}
              </StyledTableCell>
              <StyledTableCell align="left">{row?.minted}</StyledTableCell>
              <StyledTableCell align="left">
                {row?.maxSupply}
              </StyledTableCell>
              <StyledTableCell align="left">
                <div className="  flex justify-start w-full items-center gap-2 mr-3">
                  { /* <span className="block w-2 h-2 bg-green rounded-full" />{" "} */}
                  <span className={`ft-token-grid--item--btn ${row?.deployed ? ' ft-token-grid--item--btn--deployed' : ' ft-token-grid--item--btn--active'}`}>
                    {row?.deployed ? "Deployed" : "Active"}
                  </span>
                </div>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Link
                  to={"/admin/updatetoken/" + row?.nftaddress}
                  className="bg-none  hover:no-underline"
                >
                  Edit
                </Link>
                {/*<button
                    className=" rounded-sm flex items-center gap-2 px-3 py-2 ml-auto bg-[#ef4444] hover:bg-[#f87171] text-white"
                  >
                    <BsFillPencilFill /> Edit
                  </button>*/}
              </StyledTableCell>
            </StyledTableRow>
          ))
          }

        </TableBody>
      </Table>) : (
        <Loader message="Loading" />
      )}
    </TableContainer>
  );
}
