import React, { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import Input from "@mui/material/Input";
import Label from "react-bootstrap/FormLabel";
import { Button, Dialog, FormControl } from "@mui/material";
import WhiteSelect from "../../components/WhiteSelect";
import { useNavigate, useParams } from "react-router-dom";
import { InputField, StyledTextarea } from "../../components/CutomUiFields";
import { useForm, Controller } from "react-hook-form";
import { updateTokenSchema } from "../../utils/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import Loader from "../../components/Loader";

type FormValues = {
  name: string;
  symbol: string;
  collection_img: string;
  token_max_supply: string;
  collection_value: string;
  per_user_max_size: string;
  description: string;
  is_locked: boolean;
  nftAddress: string
};


export const Updatecollection = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [nftDetail, setNftDetail] = useState("" as any);
  const [symbolValue, setSymbolValue] = useState("" as any);
  const { collectionAddress } = useParams();
  /*const [values, setValues] = React.useState({
    symbol: "",
    value: "",
  });*/
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(updateTokenSchema),
  });
  useEffect(() => {

    loadNftDetails();
    //}

  }, []);
  const loadNftDetails = async () => {

    if (collectionAddress !== undefined) {
      var formBody: any[] = [];
      formBody.push("nft_address" + "=" + collectionAddress.toString());
      let bodyParams: string = formBody.join("&");
      console.log("bodyParams", bodyParams);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded', authorization: process.env.REACT_APP_Token as string },
        body: bodyParams
      };
      fetch(`${process.env.REACT_APP_URL}/api/nftFullDetail`, requestOptions)
          .then(response => response.json())
          .then((data) => {
            //debugger;
            setIsLoading(false);
            //{id: 'phi', value: 'Phi'}
            let obj = { id: 'phi', value: 'Phi' };
            //setValues((prev) => ({ ...prev, ["symbol"]: obj }));
            setValue("symbol", obj.id, { shouldValidate: true });
            setSymbolValue(obj);
            //w setValues({value:"Phi",symbol:"Phi"});
            setIsLocked(data.data.offchain_detail[0].locked);
            //setIsLocked(false);
            setNftDetail(data.data);
          }).catch((err) => {
        //debugger;
        setIsLoading(false);
        console.log("Error:" + err);
      });
    }
  };
  const handleClose = () => {
    setIsModalOpen(false);
    setToken("");
    navigate("/admin/tokenlist");
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    //debugger;
    //console.log({ ...values, [name]: event.target });
    //setValues((prev) => ({ ...prev, [name]: value }));
    setValue(name, value.id, { shouldValidate: true });
  };
  const objectToFormData = (data) => {
    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    return formData;
  };

  const postToken = async (data) => {
    const formData = objectToFormData(data);

    // var formBody: any[] = [];
    // for (let param in data) {
    //   var encodedKey = encodeURIComponent(param);
    //   var encodedValue = encodeURIComponent(data[param]);
    //   formBody.push(encodedKey + "=" + encodedValue);
    // }

    // let bodyParams: string = formBody.join("&");
    const requestOptions = {
      method: "POST",
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        authorization: process.env.REACT_APP_Token as string,
      },
      body: formData,
    };

    const res = await fetch(
        `${process.env.REACT_APP_URL}/api/updateSBCollection`,
        requestOptions
    );

    return res.json();
  };

  const onSubmit = async (data) => {
    //console.log("submit");
    debugger;
    //console.log(data.token_max_supply);
    try {
      const formValues = {
        ...data,
        collection_img: data.collection_img[0],
        is_locked: watch("is_locked") ? 1 : 0,
        nftAddress: collectionAddress
      };
      //debugger;
      // formValues.image_name = "collection1.png";
      setIsUpdating(true);
      const response = await postToken(formValues);
      setIsUpdating(false);
      if (response.statusCode === 200) {
        setToken(response.data);
        setIsModalOpen(true);
      }
    } catch (error) {
      //debugger;
      setIsUpdating(false);
    }
  };

  return (
      <>
        {nftDetail.onchain_detail != undefined ? (


            <section className="create_token">

              <div className={'container container-form section-container-form'}>
                <div className="ft-form-container">
                  <div className="ft-bg"></div>

                  <form onSubmit={handleSubmit(onSubmit)}>


                    <div className="ft-form">

                      <div className={'ft-form-title'}>
                        Update Token
                      </div>

                      <FormControl fullWidth className="mb-4">
                        <InputField
                            fullWidth
                            id="standard-basic"
                            label="Name"
                            defaultValue={nftDetail.onchain_detail.name}
                            variant="standard"
                            {...register("name")}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />
                      </FormControl>
                      <WhiteSelect
                          value={symbolValue}
                          handleChange={handleChange}
                          data={[
                            { id: "phi", value: "Phi" }
                          ]}
                          label="Symbol"
                          name="symbol"
                          error={errors.symbol?.message}
                      />
                      <div className="flex justify-between mb-4">
                        <div>
                          <p>Soulbond</p>
                          <p className="color-lavender-gray">
                            Make this token nontransferable upon claim
                          </p>
                        </div>
                        <Controller
                            name="is_locked"
                            control={control}
                            defaultValue={isLocked}

                            render={({ field }) => (
                                <Switch id="isSoulBound" {...field} value={isLocked} defaultChecked={isLocked} className={'ft-form-switch'} />
                            )}
                        />
                      </div>

                      <div className="mb-4 ft-form-upload-button-container">
                        <Label className="block">Upload New Image</Label>
                        <Input
                            type="file"
                            {...register("collection_img", {
                              required: "The File Field is Required",
                            })}
                        />

                      </div>
                      <FormControl fullWidth className="mb-4">
                        <InputField
                            fullWidth
                            id="standard-basic"
                            label="Token Value"
                            variant="standard"
                            defaultValue={nftDetail.offchain_detail[0].collection_value}
                            {...register("collection_value")}
                            error={!!errors.collection_value}
                            helperText={errors.collection_value?.message}
                        />
                      </FormControl>
                      <FormControl fullWidth className="mb-4">
                        <InputField
                            fullWidth
                            id="standard-basic"
                            label="Max Supply"
                            variant="standard"
                            {...register("token_max_supply")}
                            error={!!errors.token_max_supply}
                            defaultValue={nftDetail.offchain_detail[0].maxSupply}
                            helperText={errors.token_max_supply?.message}
                        />
                      </FormControl>

                      <FormControl fullWidth className="mb-4">
                        <InputField
                            fullWidth
                            id="standard-basic"
                            label="Max Supply Per User"
                            variant="standard"
                            {...register("per_user_max_size")}
                            error={!!errors.per_user_max_size}
                            defaultValue={nftDetail.offchain_detail[0].maxSupplyPerWallet}
                            helperText={errors.per_user_max_size?.message}
                        />
                      </FormControl>

                      <div className="mb-4 ft-form-textarea-container">
                        <label className="block form-label">Description</label>
                        <StyledTextarea minRows={3} {...register("description")} defaultValue={nftDetail.offchain_detail[0].description} />
                        {errors.description && (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {errors.description.message}
                            </p>
                        )}
                      </div>


                      <div className="">
                        <button className="ft-button w-full" data-component-style={"1"}>
                          UPDATE
                        </button>
                      </div>


                    </div>
                    {/*
              <div className="grid grid-cols-1  ml-[20px] mr-[30px]   lg:grid-cols-1 lg:mx-[150px] ">
                <button className="  mt-4  px-3 py-2  bg-btnbg border rounded lg:rounded-3xl  text-[18px] lg:text-[18px]	 fw-400">
                  UPDATE
                </button>
              </div>
                */}
                  </form>
                </div>
              </div>
            </section>) : (isLoading === true && <Loader message="Loading" />)}
        {isUpdating === true && <Loader message="Updating Token" />}
        <Dialog onClose={() => setIsModalOpen(false)} open={isModalOpen}>
          <div className="p-5">
            <h2 className="mb-4">Token Updated</h2>
            <p className="mb-4 text-sm">{token}</p>
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>
          </div>
        </Dialog>

      </>
  );
};
