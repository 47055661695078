export const DiscordCallback = () => {
  debugger;
  return (
    <div className='mt-[100px]'>
      <hr className='bg-white' />

      <div className="justify-center flex mt-[20px]">

        <p className="mx-2 font-bold text-white">Descord Callback</p>
        <p className="mx-2 font-bold text-white">Website Callback</p>
        <hr className="mt-[100px]" />

      </div>
    </div>
  )
}

//export default Footer