import { InputField } from "../../components/CutomUiFields";
import { FormControl } from "@mui/material";
import FaucetGrid from "./faucit_grid";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { generalSchema } from "../../utils/schemas";
import { GeneralFormValues } from "../../types/models";
import {SaButton} from "../../components/common/saButton/saButton";
import TokenGrid from "./token_grid";
import React from "react";

export const Faucetlisting = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<GeneralFormValues>({
    resolver: zodResolver(generalSchema),
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
      <>
        <section className={'section ft-token-list'} data-name={'section'} data-section={'section-token-list'}>
          {/*
        <div className={'container section-title text-left'} >
          <div className="section-before-title">All Proposals</div>
          <h1  className={'section-title--h1'} >
            <span>FURLOUGH </span> <span className={'ft-text-gradient--proposals-1'}>DAO GOVERNANCE</span>
          </h1>
          <div  className={'section-subtitle'} >
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </span>
          </div>
        </div>
        */}

          <div className={'container section-content'}>

            <div className="ft-special-header">
              <h2 className="ft-special-header--title"><span className={'ft-text-gradient--special-1'}>FURLOUGH'S</span> FAUCETS</h2>
              <SaButton link={`/admin/creatfaucet`} title={`CREATE FAUCET`}  ariaLabel={`Create Faucets`} />
            </div>


            <div className="ft-special-content">
              <FaucetGrid />
            </div>
          </div>
        </section>



        {/*
          <section className="faucet__list">
            <div className="container">
              <div className="text-white">
                <h5 className="text-white">All Proposals</h5>
                <p className="text-[25px] mt-6 font-bold text-white">
                  Furlough's Faucets
                </p>
              </div>

              <div className="  px-9 py-5 border rounded-md    mt-[50px]">
                <div className="flex justify-between align-middle mb-3">
                  <p className="text-[25px]  font-bold text-white">
                    Furlough's Faucets
                  </p>
                  <Link
                    to="/admin/creatfaucet"
                    className="bg-none text-[#75f3d5] hover:no-underline"
                  >
                    + Create
                  </Link>
                </div>
                <FaucetGrid />
              </div>


            </div>
          </section>

        */}


        </>
  );
};
