import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Input from "@mui/material/Input";
import Label from "react-bootstrap/FormLabel";
import { Button, Dialog, FormControl } from "@mui/material";
import WhiteSelect from "../../components/WhiteSelect";
import { InputField, StyledTextarea } from "../../components/CutomUiFields";
import { useForm, Controller } from "react-hook-form";
import { createTokenSchema } from "../../utils/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";

type FormValues = {
  name: string;
  symbol: string;
  collection_img: string;
  token_max_supply: string;
  collection_value: string;
  per_user_max_size: string;
  description: string;
  is_locked: boolean;
};

export const Createcollection = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = React.useState({
    symbol: "",
    value: "",
  });
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(createTokenSchema),
  });

  const handleClose = () => {
    setIsModalOpen(false);
    setToken("");
    navigate("/admin/tokenlist");
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    debugger;
    console.log({ ...values, [name]: event.target });
    setValues((prev) => ({ ...prev, [name]: value }));
    setValue(name, value.id, { shouldValidate: true });
  };
  const objectToFormData = (data) => {
    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    return formData;
  };

  const postToken = async (data) => {
    const formData = objectToFormData(data);

    // var formBody: any[] = [];
    // for (let param in data) {
    //   var encodedKey = encodeURIComponent(param);
    //   var encodedValue = encodeURIComponent(data[param]);
    //   formBody.push(encodedKey + "=" + encodedValue);
    // }

    // let bodyParams: string = formBody.join("&");
    const requestOptions = {
      method: "POST",
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        authorization: process.env.REACT_APP_Token as string,
      },
      body: formData,
    };

    const res = await fetch(
      `${process.env.REACT_APP_URL}/api/mintSBCollection`,
      requestOptions
    );

    return res.json();
  };

  const onSubmit = async (data) => {
    //console.log(data.token_max_supply);
    try {
      const formValues = {
        ...data,
        collection_img: data.collection_img[0],
        is_locked: watch("is_locked") ? 1 : 0,
      };
      // formValues.image_name = "collection1.png";
      setIsLoading(true);
      const response = await postToken(formValues);
      setIsLoading(false);
      if (response.statusCode == 200) {
        setToken(response.data);
        setIsModalOpen(true);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className="create_token">

        <div className={'container container-form section-container-form'}>
          <div className="ft-form-container">
            <div className="ft-bg"></div>

            <form onSubmit={handleSubmit(onSubmit)}>



              <div className="ft-form">

                <div className={'ft-form-title'}>
                  Create a new Token
                </div>

                <FormControl fullWidth className="mb-4">
                  <InputField
                    fullWidth
                    id="standard-basic"
                    label="Name"
                    variant="standard"
                    {...register("name")}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                </FormControl>

                {/*<FormControl fullWidth className="mb-4">
                  <InputField
                    fullWidth
                    id="standard-basic"
                    label="Symbol"
                    variant="standard"
                    {...register("symbol")}
                    error={!!errors.symbol}
                    helperText={errors.symbol?.message}
                  />
                </FormControl>*/}
                <WhiteSelect
                  value={values.symbol}
                  handleChange={handleChange}
                  data={[
                    { id: "phi", value: "Phi" }
                  ]}
                  label="Symbol"
                  name="symbol"
                  error={errors.symbol?.message}
                />
                <div className="flex justify-between mb-4">
                  <div>
                    <p>Soulbond</p>
                    <p className="color-lavender-gray">
                      Make this token nontransferable upon claim
                    </p>
                  </div>
                  <Controller
                    name="is_locked"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Switch id="isSoulBound" {...field} value={false} className={'ft-form-switch'} />
                    )}
                  />
                </div>

                <div className="mb-4 ft-form-upload-button-container">
                  <Label className="block">Upload New Image</Label>
                  <Input
                    type="file"
                    {...register("collection_img", {
                      required: "The File Field is Required",
                    })}
                  />
                  {errors.collection_img && (
                    <p
                      style={{ color: "red", fontSize: "12px" }}
                      className="mt-1"
                    >
                      {errors.collection_img.message}
                    </p>
                  )}
                </div>
                <FormControl fullWidth className="mb-4">
                  <InputField
                    fullWidth
                    id="standard-basic"
                    label="Token Value"
                    variant="standard"
                    {...register("collection_value")}
                    error={!!errors.collection_value}
                    helperText={errors.collection_value?.message}
                  />
                </FormControl>
                <FormControl fullWidth className="mb-4">
                  <InputField
                    fullWidth
                    id="standard-basic"
                    label={<span>Max Supply <span className='color-lavender-gray'>(Optional)</span></span>}
                    variant="standard"
                    {...register("token_max_supply")}
                    error={!!errors.token_max_supply}
                    helperText={errors.token_max_supply?.message}
                  />
                </FormControl>

                <FormControl fullWidth className="mb-4">
                  <InputField
                    fullWidth
                    id="standard-basic"
                    label="Max Supply Per User"
                    variant="standard"
                    {...register("per_user_max_size")}
                    error={!!errors.per_user_max_size}
                    helperText={errors.per_user_max_size?.message}
                  />
                </FormControl>

                <div className="mb-4 ft-form-textarea-container">
                  <label className="block form-label">Description</label>
                  <StyledTextarea minRows={3} {...register("description")} />
                  {errors.description && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.description.message}
                    </p>
                  )}
                </div>


                <div className="">
                  <button className="ft-button w-full" data-component-style={"1"}>
                    CREATE
                  </button>
                </div>

              </div>

            </form>
          </div>
        </div>
      </section>
      {isLoading == true && <Loader message="Generating Token" />}
      <Dialog onClose={() => setIsModalOpen(false)} open={isModalOpen}>
        <div className="p-5">
          <h2 className="mb-4">Token Generated</h2>
          <p className="mb-4 text-sm">{token}</p>
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Dialog>
    </>
  );
};
