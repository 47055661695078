import { MenuItem, Select, TextareaAutosize } from "@mui/material";
import TextField from "@mui/material/TextField";

import { styled } from "@mui/system";
import { BsChevronExpand } from "react-icons/bs";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

export const InputField = styled(TextField)`
  & .MuiInput-input {
    margin: 0 !important;
  }

  & label.Mui-focused {
    color: white;
    border-bottom-color: white;
  }

  & .MuiInputLabel-root {
    left: 0 !important;
    color: white;
  }

  & .MuiInput-underline:after {
    border-bottom-color: white;
  }
  & .MuiInput-underline:before {
    border-bottom-color: white;
  }
`;

export const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
      width: 100%;
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      padding: 12px;
      border-radius: 4px;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]
    };
    
  
      &:hover {
        border-color: ${blue[400]};
      }
  
      &:focus {
        border-color: ${blue[400]};
        box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
      }
  
      // firefox
      &:focus-visible {
        outline: 0;
      }
    `
);

export const SelectDropdown = ({
  value,
  onChange,
  data,
}: {
  value: any;
  onChange: any;
  data: any;
}) => {
  return (
    <Select
      value={value}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      onChange={onChange}
      IconComponent={BsChevronExpand}
      autoWidth
      sx={{
        color: "white",
        padding: "0 !important",
        "& .MuiSelect-icon ": {
          color: "white",
        },
      }}
      renderValue={(value) => {
        return (
          <div className="  flex w-full items-center gap-2   mr-3">
            <span className="block w-1 h-1 bg-green rounded-full" />{" "}
            <span>{value || "Select...."}</span>
          </div>
        );
      }}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>

      {data &&
        data.map((el) => {
          return (
            <MenuItem key={el.name} value={el.name}>
              {el.name}
            </MenuItem>
          );
        })}
    </Select>
  );
};
