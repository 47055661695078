import React, { useState, useEffect } from 'react';
//import './SaImage.css';

interface SaImageProps {
    src: string;
    classes?: string;
    alt?: string;
}

export const SaImage: React.FC<SaImageProps> = ({ src, classes, alt }) => {
    const [aspectRatio, setAspectRatio] = useState<number | null>(null);

    useEffect(() => {
        const getImageSize = () => {
            const img = new Image();
            img.src = src;

            img.onload = () => {
                const ratio = img.width / img.height;
                setAspectRatio(ratio);
            };
        };

        getImageSize();
    }, [src]);

    const containerStyle: React.CSSProperties = {
        position: 'relative',
        paddingBottom: aspectRatio ? `${(1 / aspectRatio) * 100}%` : '100%', // Используйте вычисленное соотношение сторон или 100% по умолчанию
    };

    return (
        <div className={`ft-image-container ${classes || ''}`} style={containerStyle}>
            <img className="ft-image" src={src} alt={alt || 'dao'} />
        </div>
    );
};
