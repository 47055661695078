import React, { useEffect, useState } from "react";
//import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
//import { TopBidList } from "../components/topBidList";
import auction_dummy from "../assets/images/Phigital.gif";
import { API_HOST } from "../utils/host";
import { Transaction, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { Metaplex, walletAdapterIdentity } from "@metaplex-foundation/js";
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { ErrorPopUp } from "./popup/errorPopUp";
import { NotificationPopUp } from "./popup/notification";

import './auction.css'


export const Auction = () => {
  const { connection } = useConnection()
  const wallet = useWallet()
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [minutesElapsed, setMinutesElapsed] = useState(0);
  const [bidPrice, setBidPrice] = useState(0);
  const [nftDetail, setNftDetail] = useState("" as any);
  const [nftBids, setNftBids] = useState([] as any);

  const [nftBidPrices, setNftBidPrices] = useState([] as any);
  const [modalShow, setModalShow] = useState(false);
  
  const [minimumBidPrice, setMinimumBidPrice] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [notificationModalShow, setNotificationModalShow] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  function calculateTimeLeft() {
    let now = new Date();
    let tomorrow = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1
    );
    let difference = tomorrow.getTime() - now.getTime();

    return {
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      let newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

      // Check if a minute has passed
      if (newTimeLeft.seconds === 0 && newTimeLeft.minutes !== minutesElapsed) {
        setMinutesElapsed(newTimeLeft.minutes);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [minutesElapsed]);
  /*useEffect(() => {

    loadNftDetails();

    loadNftBids();

  }, []);*/
  useEffect(() => {
  }, [nftBids, nftBidPrices, minimumBidPrice, bidPrice]);
  const handleBidPrice = (e) => {
    // 👇 Store the input value to local state
    //debugger;
    setBidPrice(e.target.value);
  };
  const today = new Date();
  today.setDate(today.getDate());

  const placeBidMethod = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    //debugger;
    //e.preventDefault();
    
    setNotificationMessage("Once initial mint is completed auction will begin.");
    setNotificationModalShow(true);
    //debugger;
    /*if (Number(bidPrice) >= Number(minimumBidPrice + 1)) {
      await placeBidFinal();
    }
    else {
      setErrorMessage("Bid Amount must be atleast 1 Sol Greater or Equal then the last top Bid.");
      setModalShow(true);
    }*/
  };
  const placeBidFinal = async () => {

    if (wallet.connected && wallet.publicKey !== undefined&&wallet.publicKey!==null) {

      const metaplex = Metaplex.make(connection).use(
        walletAdapterIdentity(wallet)
      );
      let availableBalance = await metaplex.connection.getBalance(wallet.publicKey);
      availableBalance = availableBalance / LAMPORTS_PER_SOL;
      if (availableBalance > bidPrice) {

        /*const params = {
          frontEnd_Address: wallet.publicKey.toBase58(),
          bid_amount: bidPrice.toString()
        };

        const config = {
          headers: {
            authorization: process.env.REACT_APP_Token as string,
          }
        };*/

        var formBody: any[] = [];
        formBody.push("bid_amount=" + bidPrice.toString());
        formBody.push("frontEnd_Address=" + wallet.publicKey.toBase58())
        let bodyParams: string = formBody.join("&");
        console.log("bodyParams", bodyParams);
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded', authorization: process.env.REACT_APP_Token as string },
          body: bodyParams
        };
        fetch(`${API_HOST}/api/placeBid`, requestOptions)
          .then((response) => response.json())
          .then(async (data) => {
            //debugger;
            try {
              if (data.message === 'not_allowed') {

                setNftBids(data.data.bids);

                setNftBidPrices(data.data.prices);

                if (data.data.prices.length > 0) {

                  setMinimumBidPrice(Number(data.data.prices[0].toString()));
                } else {

                  setMinimumBidPrice(0);
                }
                setErrorMessage("Bid Amount must be atleast 1 Sol Greater or Equal then the last top Bid.");
                setModalShow(true);
              } else {

                let trans_obj = Transaction.from(new Uint8Array(Buffer.from(data.data.data, 'base64')));

                const signedTransaction = await metaplex.identity().signTransaction(trans_obj);
                metaplex.rpc().sendTransaction(signedTransaction, { skipPreflight: true }).then((resp) => {
                  //debugger;
                  setBidPrice(0);
                  //loadNftBids();
                }).catch((err) => {
                  setBidPrice(0);
                  setErrorMessage(err);
                  setModalShow(true);

                })
              }

            } catch (err) {
              console.log(err);
              setErrorMessage("You must have enough balance in your wallet to place a bid.");
              setModalShow(true);
            }
          }).catch((err) => {
            console.log(err);
            setErrorMessage("You must have enough balance in your wallet to place a bid.");
            setModalShow(true);
          });
      }
      else {
        //debugger;
        setErrorMessage("You must have enough balance in your wallet to place a bid.");
        setModalShow(true);
      }
    } else {
      setErrorMessage("Please connect your wallet first.");
      setModalShow(true);
    }
  }
  /*const loadNftDetails = async () => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded', authorization: process.env.REACT_APP_Token as string },
    };
    fetch(`${API_HOST}/api/getActiveListingDetail`, requestOptions)
      .then(response => response.json())
      .then((data) => {
        //debugger;
        setNftDetail(data.data);
      }).catch((err) => {
        console.log("Error:" + err);
      });
  };
  const loadNftBids = async () => {
    
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded', authorization: process.env.REACT_APP_Token as string },
    };
    fetch(`${API_HOST}/api/assetBidsList`, requestOptions)
      .then(response => response.json())
      .then((data) => {
        try {
          //debugger;
          setBidPrice(0);
          setNftBids(data.data.bids);

          setNftBidPrices(data.data.prices);

          if (data.data.prices.length > 0) {

            setMinimumBidPrice(Number(data.data.prices[0].toString()));
          } else {

            setMinimumBidPrice(0);
          }

        } catch (err) {
          console.log(err);
        }
      }).catch((err) => {
        console.log("Error:" + err);
      });
  };*/
  return (
    <>


      {/*{(() => {
        if (nftDetail !== undefined && nftDetail !== null) {
          if (nftDetail.json !== undefined && nftDetail.json !== null) {

            return (
              <div className="flex justify-center text-white ">
                <img src={nftDetail.json.image} alt="" />
              </div>
            )
          }
        } else {

          return (
            <div className="flex justify-center text-white ">
              <img src="/frame.png" alt="" />
            </div>
          )
        }
      })()}*/}
 <div className="flex justify-center text-white ">
              <img src={auction_dummy} alt="" />
            </div>
      <div className="ft-nft--detail">
        {(() => {
          if (nftDetail !== undefined && nftDetail !== null) {
            return (
              <div>
                <p className="ft-nft--detail--date">{today.toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}</p>

                <div className="flex items-center">
                  {/*<h1 className="ft-nft--detail--name">{nftDetail.name}</h1>*/}
                  <h1 className="ft-nft--detail--name">PHI #1</h1>
                 

                </div>
                <div className="ft-nft--detail--auction">
                <div className="ft-nft--detail--bid">
                    <p className="ft-nft--detail--label">Current bid</p>
                    <p className="ft-nft--detail--value">1</p>
                  </div>
                  <div className="ft-nft--detail--ends">
                    <p className="ft-nft--detail--label">
                      Ends in{" "}
                    </p>
                    <p className="ft-nft--detail--value">{`24h 0m 0s`}</p>
                  </div>
                  
                  {/*<div className="ft-nft--detail--bid">
                    <p className="ft-nft--detail--label">Current bid</p>
                    <p className="ft-nft--detail--value">{minimumBidPrice}</p>
                  </div>
                  <div className="ft-nft--detail--ends">
                    <p className="ft-nft--detail--label">
                      Ends in{" "}
                    </p>
                    <p className="ft-nft--detail--value">{`${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`}</p>
                  </div>*/}
                </div></div>
            )
          }
        })()}
        {(() => {
          if (nftDetail !== undefined && nftDetail !== null) {
            return (
              <div className="ft-nft--detail--input-and-button">
                <div className="ft-nft--detail--input-row">
                  {/*<p className="ft-nft--detail--input-label">Minimum bid: {Number(minimumBidPrice) + 1} SOL</p>*/}
                  <p className="ft-nft--detail--input-label">Minimum bid: 1 SOL</p>
                  <input
                    className="ft-nft--detail--input"
                    /*style={{ color: "black" }}*/
                    type="number"
                    placeholder="SOL" onChange={handleBidPrice} value={bidPrice}
                  />
                </div>
                <div className="ft-nft--detail--button">
                  <button
                    className="ft-button"
                    data-component-style="1"
                    onClick={placeBidMethod}
                  >
                    BID
                  </button>
                </div>
              </div>)
          }
        })()}
        <div className="ft-nft--detail--auction">
                
                  <div className="ft-nft--detail--ends">
                    <p className="ft-nft--detail--label">
                    Once initial mint is completed auction will begin.
                    </p>
          </div>
          </div>
        {/*{(() => {
          if (nftBids !== undefined && nftBids !== null && nftBids.length > 0) {

            return (
              <TopBidList nftBids={nftBids} nftBidPrices={nftBidPrices}></TopBidList>
            )

          }
        })()}*/}

      </div>
      <ErrorPopUp
        show={modalShow}
        onHide={() => setModalShow(false)}
        message={errorMessage}
      ></ErrorPopUp>
      <NotificationPopUp
        show={notificationModalShow}
        onHide={() => setNotificationModalShow(false)}
        message={notificationMessage}
      ></NotificationPopUp>
    </>
  );
};
