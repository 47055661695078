import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { API_HOST } from "../utils/host";
interface IAuthContext {
  openAuthPopup: () => void;
  discordLogout: () => void;
  discordToken: string;
  discordProfileImage:string;
  discordGlobalName:string;
  discordUserName:string;
}

export const AuthContext = createContext<IAuthContext>({
  openAuthPopup: function (): void {
    throw new Error("Function not implemented.");
  },
  discordLogout: function (): void {
    throw new Error("Function not implemented.");
  },
  discordToken: "",
  discordProfileImage:"",
  discordGlobalName:"",
  discordUserName:""
});

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const wallet = useWallet()
  const [popup, setPopup] = useState<Window | null>(null);
  const [discordToken, setDiscordToken] = useState<string>(
    window.localStorage.getItem("discordToken") || ""
  );
  const [discordProfileImage, setDiscordProfileImage] = useState<string>(window.localStorage.getItem("discordProfileImage") || "");
  const [discordGlobalName, setDiscordGlobalName] = useState<string>(window.localStorage.getItem("discordGlobalName") || "");
  const [discordUserName, setDiscordUserName] = useState<string>(window.localStorage.getItem("discordUserName") || "");

  const openAuthPopup = () => {
    const params = new URLSearchParams({
      response_type: "code",
      scope: "identify guilds guilds.members.read messages.read",
      client_id: process.env.REACT_APP_DISCORD_CLIENT_ID as string,
    });

    const title = `WINDOW TITLE`;
    const url = `https://discordapp.com/oauth2/authorize?${params}`;
    const popup = window.open(url, title, "height=700,width=500");
    setPopup(popup);
  };

  const discordLogout = () => {
    setDiscordToken("");
    window.localStorage.removeItem("discordToken");
    window.localStorage.removeItem("discordProfile");
    console.log("your are logged out");
  };

  useEffect(() => {
    if (!popup) {
      return;
    }

    const timer = setInterval(() => {
      try {
        if (!popup || popup.closed) {
          timer && clearInterval(timer);
          return;
        }

        const currentUrl = popup?.location?.href;
        if (!currentUrl) {
          return;
        }
        const searchParams = new URL(currentUrl).searchParams;

        if (searchParams.get("error")) {
          popup.close();
          clearInterval(timer);
        }
        const code = searchParams.get("code");
        if (code) {
         
          var formBody: any[] = [];
          formBody.push("client_secret=" + process.env.REACT_APP_DISCORD_SECRET as string,);
          formBody.push("client_id=" + process.env.REACT_APP_DISCORD_CLIENT_ID as string,);
          formBody.push("grant_type=" + "authorization_code");
          formBody.push("code=" + code);
          //formBody.push("redirect_uri=" + bidPrice.toString());
          let bodyParams: string = formBody.join("&");
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'user-agent': 'phidao' },
            body: bodyParams
          };
          fetch(process.env.REACT_APP_DISCORD_TOKEN_URL as string, requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
              try {
                const requestOptionsProfile = {
                  method: 'GET',
                  headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'authorization': 'Bearer ' + data.access_token, 'accept': 'application/json', 'user-agent': 'phidao' },
                };
                const requestOptionsProfileBot = {
                  method: 'GET',
                  headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'authorization': 'Bot ' + process.env.REACT_APP_DISCORD_BOT_ID as string, 'accept': 'application/json', 'user-agent': 'phidao' },
                };
                //fetch(`https://discord.com/api/users/@me`, requestOptionsProfile)
                fetch(process.env.REACT_APP_DISCORD_USER_PROFILE as string, requestOptionsProfile)
                  .then((response) => response.json())
                  .then(async (data_profile) => {
                    try {
                      setDiscordProfileImage(process.env.REACT_APP_DISCORD_AVATAR as string+data_profile.id+"/"+data_profile.avatar+".png");
                      //debugger;
                      window.localStorage.setItem("discordProfileImage",process.env.REACT_APP_DISCORD_AVATAR as string+data_profile.id+"/"+data_profile.avatar+".png");
                      setDiscordGlobalName(data_profile.global_name);
                      window.localStorage.setItem("discordGlobalName",data_profile.global_name);
                      setDiscordUserName(data_profile.username);
                      window.localStorage.setItem("discordUserName",data_profile.username);
                      var formBody: any[] = [];
                      formBody.push("nickname=" + data_profile.global_name);
                      formBody.push("avatar=" + process.env.REACT_APP_DISCORD_AVATAR as string+data_profile.id+"/"+data_profile.avatar+".png");
                      formBody.push("walletAddress=" + localStorage.getItem("wallet_address"));
                      formBody.push("discord_code=" + code);
                      formBody.push("discord_id=" + data_profile.id);
                      let bodyParams: string = formBody.join("&");
                      //debugger;
                      const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded', authorization:  process.env.REACT_APP_Token as string },
                        body: bodyParams
                      };
                      fetch(`${API_HOST}/api/saveUserProfile`, requestOptions)
                        .then((response) => response.json())
                        .then(async (data) => {
                          //debugger;
                          try {
                            fetch(process.env.REACT_APP_DISCORD_GUILDS_URL as string, requestOptionsProfile)
                  .then((response) => response.json())
                  .then(async (data_save_profile) => {
                    try {
                            //debugger;
                          } catch (err) {
                            //debugger;
                            console.log(err);
      
                          }
                        }).catch((err) => {
                          console.log(err);
                          //debugger;
                        })
                          } catch (err) {
                            console.log(err);
                            
                          }
                        }).catch((err) => {
                          console.log(err);
                          
                        });
                    } catch (err) {
                      //debugger;
                      console.log(err);

                    }
                  }).catch((err) => {
                    console.log(err);
                    //debugger;
                  })
              } catch (err) {
                //debugger;
                console.log(err);

              }
            }).catch((err) => {
              console.log(err);
              //debugger;
            })

          //}
          setDiscordToken(code);
          window.localStorage.setItem("discordToken", code);
          
          popup.close();
          setPopup(null);
          clearInterval(timer);
        }
      } catch (error) {
        console.log("listining from popup...");
      }
    }, 500);
  }, [popup]);

  return (
    <AuthContext.Provider
      value={{ openAuthPopup, discordLogout, discordToken,discordProfileImage,discordGlobalName,discordUserName }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
