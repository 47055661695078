import { LeaderboardTopList } from "../../components/Leaderboard/LeaderboardTopList";
import { LeaderboardMonthlyList } from "../../components/Leaderboard/LeaderboardMonthlyList";
import { LeaderboardGenesisList } from "../../components/Leaderboard/LeaderboardGenesisList";
import owl from "../../assets/images/owl.png";
import "./style.css";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

export const LeaderboardPage = () => {
  const { openAuthPopup, discordLogout, discordToken } =
    useContext(AuthContext);
  return (
    <main>
      <section className="container pt-10 xl:pt-[122px]">
        <div className="BOARD_BANNER relative rounded-lg ">
          <img src={owl} className="absolute OWL hidden md:block" />
          <h1 className="TEXT_GRADIANT uppercase space-x-4 text-white">
            <span className="uppercase">Global</span>
            <span>leaderboard</span>
          </h1>
        </div>
        {/*<div className="LOGIN_WRAPPER flex flex-col md:flex-row justify-end items-end md:items-center text-white text-base">
          <p className="text-[#A5A2B1] text-sm md:text-base">
            Connect a wallet to see usernames.
          </p>
          <button
            className="LOGIN_BTN  "
            onClick={discordToken ? discordLogout : openAuthPopup}
          >
            <span className="text-2xl">
              <i className="fa-brands fa-discord"></i>
            </span>
            <span className="text-base font-[Archivo]">
              {" "}
              {discordToken ? "Logout" : "Login"}
            </span>
          </button>
        </div>*/}
      </section>
      <section className="pt-8">
        <div className="container">
          <div>
            <LeaderboardTopList />
            {/*<LeaderboardTopList />*/}
          </div>
          <div className="LEADERBOAR_GRID">
            <LeaderboardMonthlyList title="month leaderboard" />
            <LeaderboardGenesisList title="genesis leaderboard" />
          </div>
        </div>
      </section>
    </main>
  );
};
