// FtRewards.tsx
import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper';

import 'swiper/css/bundle';
import './FtRewards.css';
import { register } from 'swiper/element/bundle';

interface FtRewardsProps {
    currentDay?: number;
    rewards: { id: number; quantity: number; isActive?: boolean }[];
}



const FtRewards: React.FC<FtRewardsProps> = ({ rewards }) => {
    const swiperRef = useRef(null);

    useEffect(() => {
        // Register Swiper web component
        register();

        const swiper = new Swiper(swiperRef.current, {
            slidesPerView: 2,
            breakpoints: {
                700: {
                    slidesPerView: 5,
                },
                1200: {
                    slidesPerView: 7,
                },
            },
            spaceBetween: '20px',
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: false, // Set to true if you want to hide the scrollbar initially
            },
        });


        return () => {
            swiper.destroy();
        };
    }, []);




    function getImagePath(quantity) {
        if (quantity <= 1) {
            return '50.svg';
        } else if (quantity <= 2) {
            return '100.svg';
        } else if (quantity <= 3) {
            return '150.svg';
        } else if (quantity <= 4) {
            return '200.svg';
        } else if (quantity <= 5) {
            return '250.svg';
        } else if (quantity <= 6) {
            return '300.svg';
        } else {
            return '350.svg';
        }
    }


    return (
        <div className="ft-rewards">
            <div className="swiper-container" ref={swiperRef}>
                <div className="swiper-wrapper">
                    {rewards.map((reward, index) => (
                        <div key={index} className="swiper-slide">
                            <div className={'ft-rewards--item'} data-day={reward.id} data-active={reward.isActive ?? false}>
                                <div className={'ft-bg'}></div>
                                <div className={'ft-rewards--item--content'}>
                                    <div className={'ft-rewards--item--num'}>{reward.quantity === 1 ? `${reward.quantity} Point` : `${reward.quantity} Points`}</div>
                                    <div className={'ft-rewards--item--img'}>
                                        {/*<SaImage src={require(`./coins/${getImagePath(reward.quantity)}`)} />*/}
                                        <img src={require(`./coins/${getImagePath(reward.quantity)}`)} alt="coins" />
                                    </div>
                                </div>
                            </div>
                            <div className="ft-rewards--day" data-day={reward.id} >
                                <span>DAY {reward.id}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="swiper-scrollbar"></div>
            </div>
        </div>
    );
};

export default FtRewards;
