import { FC, useEffect, useState} from "react";
//import Button from "react-bootstrap/Button";
import { saveAs } from 'file-saver';
import EventEmitter from "../utils/eventService";
export interface ImageViewProps {
  generatedImages: any;
  imageTitle: any;
}
export const GeneratedTraits: FC<ImageViewProps> = ({ generatedImages, imageTitle }) => {
  const [nftImage, setNftImage] = useState("");
  useEffect(() => {

    if (generatedImages !== "" && generatedImages !== null && generatedImages !== undefined) {
      setNftImage(generatedImages);

    } else {
      setNftImage("https://api.phi.wtf/default.png");
    }
    const downloadImage = (eventData) => {
      //debugger;
      downloadImageMethod(null);
    }
    const listener = EventEmitter.addListener('downloadImageListener', downloadImage);
    return () => {
      // debugger;
      listener.remove();
    }
  }, [generatedImages, imageTitle]);
  const downloadImageMethod = (e: any) => {
    saveAs(generatedImages, imageTitle);
    //setCategory(e.currentTarget.value);
    //filterTraits(e.currentTarget.value);
  }
  return (
    <>
      {nftImage !== "" &&
        <div className="flex flex-wrap pr-[20px] lg:pr-[0px] ">
          <div className="mr-1 w-[100%] lg:h-[200px] lg:h-[866px] lg:mx-[15px] mb-[20px] lg:w-[55vw] text-boxclr">
            <img className="d-block w-100" src={nftImage} alt="" />
          </div>

        </div>
      }
    </>
  );
}