import { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useWallet} from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { IconMenu, IconX } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import classNames from "classnames";
import logo from "../../assets/images/logo.png";
import "./styles.css";

export const Header = () => {
  const navigate = useNavigate();
  //const { connection } = useConnection();
  let wallet = useWallet();
  let firstTimePageLoaded = useRef(false);
  const walletModel = useWalletModal();
  //const [walletAddress, setWalletAdresss] = useState("");
  //const [Loading, setLoading] = useState(false);
  //const [discordLogin, setDiscord] = useState(false);
  const { openAuthPopup, discordLogout, discordToken,discordProfileImage,discordGlobalName,discordUserName } =
  useContext(AuthContext);
  useEffect(() => {
    const onLoad = () => {
      //debugger;
      checkIfWalletConnected();
    };
    firstTimePageLoaded.current=true;
    if(firstTimePageLoaded.current&&wallet.connected){
      //debugger;
      //firstTimePageLoaded.current=false;
      navigate("/profile");
    }
    
    /*if(firstTimePageLoaded.current&&!wallet.connected){
      discordLogout();
    }*/
    
    window.addEventListener("load", onLoad);
    return () => window.removeEventListener("load", onLoad);
  }, [wallet]);

  const checkIfWalletConnected = async () => {
    try {
      //debugger;
      //setLoading(true);
      console.log(wallet.wallets);
      if (wallet.connected && wallet.publicKey !== null) {
        localStorage.setItem("wallet_address",wallet.publicKey?.toString());
        console.log("public key", wallet.publicKey?.toString());
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };
  /*const discordLoginMethod = async (e) => {
    e.preventDefault();
    //const dPopup = window.open(`${process.env.REACT_APP_DISCORD_AUTHORIZE_URL}?response_type=code&client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&scope=identify%20guilds%20guilds.members.read&redirect_uri=${process.env.REACT_APP_DISCORD_REDIRECT_URL}`, 'discordLogin', 'height=700,width=500')
    //setDiscord(true);
  };
  const discordLogoutMethod = async (e) => {
    e.preventDefault();
    try {
      setDiscord(false);
    } catch (err) {
      console.log(err);
    }
  };*/
  const connectWallet = async (e:any) => {
    e.preventDefault();
    //if(discordToken)
   // {
      //openAuthPopup();
    //}
    walletModel.setVisible(true);
  };
  const disconnectWallet = async (e:any) => {
    e.preventDefault();
    try {
      wallet.disconnect();
      localStorage.removeItem("wallet_address");
      //window.localStorage.removeItem("discordToken");
      //setWalletAdresss("");
    } catch (err) {
      console.log(err);
    }
  };
  const ref = useRef<HTMLElement | null>(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const out = (e: MouseEvent) => {
      if (ref.current && isActive && ref.current.contains(e.target as Node))
        return;
      setIsActive(false);
    };

    window.addEventListener("click", out);

    return () => window.removeEventListener("click", out);
  }, [isActive]);

  return (
    <header
      className="container flex items-center justify-between font-archivo font-base text-white pt-[25px] "
      ref={ref}
    >
      <div className="flex items-center gap-3 lg:gap-[50px] ">
        <Link to="/">
          <img src={logo} className="LOGO" alt="Phidao"/>
        </Link>
        {/*<Link to="#"> Docs</Link>*/}
      </div>

      <nav className={classNames({ active: isActive })}>
        <ul className="flex items-center">
          <li>
            <Link to="/proposals">DAO</Link>
          </li>
          <li>
            <Link to="/leaderboard">Leaderboard</Link>
          </li>
          <li>
            <Link to="/playground">Playground</Link>
          </li>
          {/*<li>
            <Link to="#">Docs</Link>
          </li>
          <li>
            <Link to="#">Discourse</Link>
          </li>
          {/*<li>
            <Link to="/admin/faucetlist">Faucets</Link>
          </li>*/}
          {/*<li>
            <Link to="/playground">Playground</Link>
          </li>
          <li>
            <div className="CONNECT_BTN_WRAPPER block md:hidden">
              <button
                onClick={!wallet.connected ? connectWallet : disconnectWallet}
              >
                {!wallet.connected ? "Connect" : "Connected"}
              </button>
            </div>
          </li>*/}
        </ul>
        {/*<button
          className="block md:hidden absolute top-3 right-3"
          onClick={() => setIsActive((prev) => !prev)}
        >
          <IconX />
        </button>*/}
      </nav>

      <div>
        <div className="CONNECT_BTN_WRAPPER hidden md:block">
          <button
            onClick={!wallet.connected ? connectWallet : disconnectWallet}
          >
            {!wallet.connected ? "Connect" : "Connected"}
          </button>
          {/*<button
            onClick={discordToken ? discordLogout : openAuthPopup}
          >
            {discordToken ? "Logout" : "Login"}
          </button>*/}
        </div>
        <button
          className="MUNU_BTN  block md:hidden "
          onClick={() => setIsActive(true)}
        >
          <IconMenu />
        </button>
      </div>
    </header>
  );
};
