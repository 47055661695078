import { useEffect, useState } from "react";
import { Traitcategories } from "../../components/traitCategories";
import { GeneratedTraits } from "../../components/generatedTraits";
import { PlaygroundButtons } from "../../components/playgroundButtons";
import EventEmitter from "../../utils/eventService";
import Loader from "../../components/Loader";
export const Playground = () => {
  //const [isActiveLoading, setActiveLoading] = useState(false);
  const [stateTraits, setLoadedTraits] = useState([]);
  const [bannedHairs, setBannedHairs] = useState([]);
  const [bannedShoes, setBannedShoes] = useState([]);
  const [stateCategories, setLoadedCategories] = useState([]);
  const [generatedImages, setGeneratedImages] = useState("");
  const [imageTitle, setImageTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  let urlBasicPath:string="https://api.phi.wtf/";
  //let urlBasicPath: string = "http://localhost:8111/";
  useEffect(() => {
    //debugger;
    loadCategories('Male');
    loadTraits('Male');
    const startStopLoadingMethod = (eventData) => {
      //debugger;
      if (eventData.params === "1") {
        startStopLoading(true);
      }
      else {
        startStopLoading(false);
      }
    }
    const listener = EventEmitter.addListener('startStopLoading', startStopLoadingMethod);
    return () => {
      // debugger;
      listener.remove();
    }
    //loadGeneratedImages('guy','assets_playground','1');
  }, []);
  const loadTraits = (type: string) => {
    //debugger;
    let categories: any = [];
    let bannedHairsdummy: any = [];
    let bannedShoesdummy: any = [];
    let traitDirectoryUrl = urlBasicPath + "api/getTraitDirectoryAndFiles";

    let params: { [key: string]: any } = {
      'typeOfTrait': type
    };
    //debugger;
    var formBody: any[] = [];
    for (let param in params) {

      var encodedKey = encodeURIComponent(param);
      var encodedValue = encodeURIComponent(params[param]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    let bodyParams: string = formBody.join("&");
    //debugger;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded', authorization: process.env.REACT_APP_Token as string },
      body: bodyParams
    };
    fetch(traitDirectoryUrl, requestOptions)
      .then(response => response.json())
      .then((data) => {
        //debugger;
        categories = data.data.fileAndTraitsArr;
        bannedHairsdummy = data.data.banned_hairs;
        bannedShoesdummy = data.data.banned_shoes;

        setBannedHairs(bannedHairsdummy);
        setBannedShoes(bannedShoesdummy);
        //debugger;
        setLoadedTraits(categories);
      });

  };
  const loadGeneratedImages = (type: any, directoryName: any, imgId: any) => {
    let imagePath: string = "";
    let traitDirectoryUrl = urlBasicPath + "api/getPlayGoundImages";

    let params: { [key: string]: any } = {
      'typeOfTrait': type, 'directoryName': directoryName, 'imgId': imgId
    };
    var formBody: any[] = [];
    for (let param in params) {

      var encodedKey = encodeURIComponent(param);
      var encodedValue = encodeURIComponent(params[param]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    let bodyParams: string = formBody.join("&");
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded', authorization: process.env.REACT_APP_Token as string },
      body: bodyParams
    };
    // debugger;
    fetch(traitDirectoryUrl, requestOptions)
      .then(response => response.json())
      .then((data) => {
        //   debugger;
        imagePath = data.data;
        setImageTitle(imagePath);
        imagePath = urlBasicPath + imagePath;

        setGeneratedImages(imagePath);
      });
  };
  const startLoading = (isActive: boolean) => {
    //debugger;
    //setActiveLoading(isActive);
    //setIsLoading(isActive);
  }
  const startStopLoading = (isActive: boolean) => {
    // debugger;
    //setActiveLoading(isActive);
    setIsLoading(isActive);
  }
  /*const downloadHandler = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };*/
  const loadCategories = (type: string) => {

    //let imagePath: string = "";
    let traitCategoryUrl = urlBasicPath + "api/getTraitCategories";

    let params: { [key: string]: any } = {
      'typeOfTrait': type
    };
    var formBody: any[] = [];
    for (let param in params) {

      var encodedKey = encodeURIComponent(param);
      var encodedValue = encodeURIComponent(params[param]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    let bodyParams: string = formBody.join("&");
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded', authorization: process.env.REACT_APP_Token as string },
      body: bodyParams
    };
    fetch(traitCategoryUrl, requestOptions)
      .then(response => response.json())
      .then((data) => {
        //debugger;
        setLoadedCategories(data.data);
      });
  };

  return (
    <>
      {isLoading === true && <Loader />}
      {/*<img src="/playground-hero.png" alt="hero" />*/}
      <div className="playground-main bg-black px-[12px] py-[6px] w-100">
        <div className="grid grid-cols-1 pl-[18px] pr-[30px] lg:px-[150px] bg-black">
          <p className="text-[25px] mt-[112px] font-bold text-white font-russo text-5xl">
            Playground
          </p>

        </div>

        <div className="grid grid-cols-1  gap-10 lg:grid-cols-1 px-[20px] lg:px-[150px] mt-[30px]">
          <div className="flex lg:flex-row flex-col">
            <Traitcategories traitList={stateTraits} bannedHairsList={bannedHairs} bannedShoesList={bannedShoes}
              categoryList={stateCategories} getTraitImage={loadGeneratedImages} startLoading={startLoading} />
            <GeneratedTraits generatedImages={generatedImages} imageTitle={imageTitle} />
          </div>
        </div>
        <PlaygroundButtons testProp="test" />

      </div>
    </>
  );
}
