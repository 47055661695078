import { object, string, any, number, coerce } from "zod";

export const createFaucetTokenSchema = object({
  tokenName: string()
    .nonempty("Token Name is required")
    .max(100, "Name must be less than 100 characters"),
  symbol: string()
    .nonempty("Symbol is required")
    .max(100, "Name must be less than 100 characters"),
  maxSupplyPerUser: string()
    .nonempty("Max Supply Per User is required")
    .max(100, "Name must be less than 100 characters"),

  file: any().refine((val) => val.length > 0, "File is required"),
});

export const createTokenSchema = object({
  name: string()
    .nonempty("Token Name is required")
    .max(100, "Name must be less than 100 characters"),
  /*symbol: string()
    .nonempty("Symbol is required")
    .max(100, "Name must be less than 100 characters"),*/
  collection_value: coerce
    .number()
    .min(1, "Token Value is required")
    .int()
    .nonnegative("must be a positive number"),
  per_user_max_size: coerce
    .number()
    .min(1, "Max Supply Per User is required")
    .int("Must be a number")
    .nonnegative("must be a positive number"),
  token_max_supply: coerce
    .number()
    //.min(1, "Max Supply Per User is required")
    .int("Must be a number")
    .nonnegative("must be a positive number"),
  description: string()
    .nonempty("Description is required")
    .max(100, "Name must be less than 100 characters"),
  symbol: string().nonempty("Symbol is required"),
  collection_img: any().refine((val) => val.length > 0, "File is required"),
});
export const loginSchema = object({
  user_email: string()
    .nonempty("Email is required")
    .email("Email format is not valid."),
    password: string()
    .nonempty("Password is required")
   
});
export const updateTokenSchema = object({
  name: string()
    .nonempty("Token Name is required")
    .max(100, "Name must be less than 100 characters"),
  /*symbol: string()
    .nonempty("Symbol is required")
    .max(100, "Name must be less than 100 characters"),*/
  collection_value: coerce
    .number()
    .min(1, "Token Value is required")
    .int()
    .nonnegative("must be a positive number"),
  per_user_max_size: coerce
    .number()
    .min(1, "Max Supply Per User is required")
    .int("Must be a number")
    .nonnegative("must be a positive number"),
  token_max_supply: coerce
    .number()
    //.min(1, "Max Supply Per User is required")
    .int("Must be a number")
    .nonnegative("must be a positive number"),
  description: string()
    .nonempty("Description is required")
    .max(100, "Name must be less than 100 characters"),
  symbol: string().nonempty("Symbol is required"),
  collection_img: any(),
});
export const createFaucetSchema = object({
  title: string()
    .nonempty("Faucet Name is required")
    .max(100, "Name must be less than 100 characters"),
  type: string().nonempty("Faucet Type is required"),
  collectionaddress: string().nonempty(" Token Type is required"),
});
export const fundReloadSchema = object({
    amount: coerce
    .number()
    .min(1, "Amount Value is required")
    .int()
    .nonnegative("must be a positive number"),
});
type FormValues = {
  proposalTitle: string;
  summary: string;
  methadology: string;
  conclusion: string;
};

export const generalSchema = object({
  proposalTitle: string()
    .nonempty("Proposal Title is required")
    .max(100, "Name must be less than 100 characters"),
  summary: string()
    .nonempty("Summary is required")
    .max(100, "Name must be less than 100 characters"),
  methodology: string()
    .nonempty("Methadology is required")
    .max(100, "Name must be less than 100 characters"),
  conclusion: string()
    .nonempty("Token Name is required")
    .max(100, "Conclusion be less than 100 characters"),
});
export const sbTokenSchema = object({
  name: string()
    .nonempty("Name is required")
    .max(100, "Name must be less than 100 characters"),
  symbol: string()
    .nonempty("Symbol is required")
    .max(100, "Symbol must be less than 100 characters"),
  description: string()
    .nonempty("Discription is required")
    .max(100, "Discription must be less than 100 characters"),
});
