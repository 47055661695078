import "./style.css";
//import { Link } from "react-router-dom";
import React,{useState,useEffect} from "react";
import { useWallet,useConnection} from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router-dom";
import {SaButton} from "../../components/common/saButton/saButton";
import { Metaplex, PublicKey, walletAdapterIdentity } from "@metaplex-foundation/js";

interface ActionItemProps {
  number?: number;
  text: string;
  status: 'Active' | 'Executed';
  link?: string;
  ariaLabel?: string;
}

const ActionItem: React.FC<ActionItemProps> = ({  number,text, status, link, ariaLabel }) => {
  const getStatusClassName = () => {
    switch (status) {
      case 'Active':
        return 'ft-proposals-item--btn--active';
      case 'Executed':
        return 'ft-proposals-item--btn--executed';
      default:
        return '';
    }
  };

  const formattedText = number ? `${number}. ${text}` : text;

  return (
      <div className={`ft-proposals-item`}
           data-status={status}
      >
        <div className="ft-bg"></div>
        <div className="ft-proposals-item--title">{formattedText}</div>
        <a
            href={link}
            className={`ft-proposals-item--btn ${getStatusClassName()}`}
            aria-label={ariaLabel}
        >
          {status}
        </a>
      </div>
  );
};


export const Proposals = () => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const [foundNft, setFoundNft] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    checkNfts();
  }, [wallet]);
  const checkNfts = async () => {

    if (wallet.connected && wallet.publicKey !== undefined&&wallet.publicKey!==null) {
//debugger;
      const metaplex = Metaplex.make(connection).use(
        walletAdapterIdentity(wallet)
      );
      await metaplex.nfts().findAllByOwner({owner:wallet.publicKey}).then((allNfts)=>{
if(allNfts.length>0){
  //debugger;
  setFoundNft(true);
}else{
  //debugger;
  setFoundNft(false);
}
      }).catch((err)=>{

      });
      //let availableBalance = await metaplex.connection.getBalance(wallet.publicKey);
    }
  }
  return (
    <div>

      <section className={'section'} data-name={'section'} data-section={'section-all-proposals'}>
        <div className={'container section-title text-left'} >
          <div className="section-before-title">All Proposals</div>
          <h1  className={'section-title--h1'} >
            <span>FURLOUGH </span> <span className={'ft-text-gradient--proposals-1'}>DAO GOVERNANCE</span>
          </h1>
          <div  className={'section-subtitle'} >
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </span>
          </div>
        </div>
        <div className={'container section-content'}>


          <div className="ft-special-header ft-proposals-header">
            <h2 className="ft-proposals-header--title">PROPOSALS</h2>
            {wallet.connected
        ? <SaButton link={foundNft ?`/createproposal`:``} isEnabled={!wallet.connected ? false : true} title={`CREATE PROPOSAL`}  ariaLabel={`Create Proposal`} classes={foundNft?'':'ft-button-disabled'} />
        : <SaButton link={foundNft ?`/createproposal`:``} isEnabled={!wallet.connected ? false : true} title={`CREATE PROPOSAL`}  ariaLabel={`Create Proposal`}/>
      }
            

          </div>


          <div className="ft-special-content ft-proposals-content">

            <ActionItem
                number={1}
                text={`Invest 20% revenue into a new project`}
                status={"Active"}
                link={`#`}
                ariaLabel={`proposal`}
            />

            <ActionItem
                number={2}
                text={`Partner with a SaaS company`}
                status={"Active"}
                link={`#`}
                ariaLabel={`proposal`}
            />

            <ActionItem
                number={3}
                text={`Airdrop new tokens for sub-community projects`}
                status={"Active"}
                link={`#`}
                ariaLabel={`proposal`}
            />

            <ActionItem
                number={4}
                text={`Vote a new name for the marketing project`}
                status={"Executed"}
                link={`#`}
                ariaLabel={`proposal`}
            />

            <ActionItem
                number={5}
                text={`Collaborate with another Discord community`}
                status={"Executed"}
                link={`#`}
                ariaLabel={`proposal`}
            />

            <ActionItem
                number={6}
                text={`Release new merchandise for the holiday season`}
                status={"Executed"}
                link={`#`}
                ariaLabel={`proposal`}
            />



          </div>

        </div>
      </section>

      {/*}
      <div className="grid grid-cols-1 pl-[18px] pr-[30px] lg:px-[150px] eclipse ">
        <h5 className="text-white">All Proposals</h5>
        <p className="text-[25px] mt-6 font-bold text-white">
          Furlough DAO Governance
        </p>
        <p className="mt-6 text-justify  text-white">
          {" "}
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        </p>

        <div className="flex items-center justify-between my-[50px]">
          <p className="text-[25px]  font-bold text-white">Proposals</p>
          <Link to="/createproposal" className="hover:text-white">
            <button className="  text-white sm:px-2 sm:py-2 lg:px-3 lg:py-3  bg-blue border rounded-md lg:rounded-md lg:mr-4 text-[14px] lg:text-[15px]	 fw-400">
              CREATE PROPOSAL
            </button>
          </Link>
        </div>
      </div>

      <div className="grid grid-cols-1 pl-[18px] pr-[30px] lg:px-[150px]  ">
        <div className="flex items-center justify-between  border-4 border rounded-md border-white p-6 mb-4">
          <p className="text-[14px] lg:text-[20px] text-white">
            1. Invest 20% revenue into a new project{" "}
          </p>
          <button className="  text-white  px-[32px] py-2  bg-btnbg border rounded-md lg:rounded-2xl lg:mr-4 text-[14px] lg:text-[15px]	 fw-400">
            Active
          </button>
        </div>

        <div className="flex items-center justify-between border  border-4 rounded-md border-white p-6 mb-4 border-2	 ">
          <p className="text-[14px] lg:text-[20px] text-white">
            2. Partner with a SaaS company
          </p>
          <button className="  text-white px-[32px] py-2  bg-btnbg border rounded-md lg:rounded-2xl lg:mr-4  text-[14px] lg:text-[15px]	 fw-400">
            Active
          </button>
        </div>

        <div className="flex items-center justify-between border border-4 rounded-md border-white p-6 mb-4 ">
          <p className="text-[14px] lg:text-[20px] text-white">
            3. Airdrop new tokens for sub-community projects
          </p>
          <button className="text-white  px-[32px] py-2  bg-btnbg border rounded-md lg:rounded-2xl lg:mr-4 text-[14px] lg:text-[15px]	 fw-400">
            Active
          </button>
        </div>

        <div className="flex  items-center justify-between  rounded-md border-white p-4 mb-4 bg-black ">
          <p className="text-[14px] lg:text-[20px] text-white">
            4. Vote a new name for the marketing project{" "}
          </p>
          <button className=" text-white  px-4 py-2  bg-purple border rounded-md lg:rounded-2xl lg:mr-4 text-[14px] lg:text-[15px]	 fw-400">
            Executed
          </button>
        </div>

        <div className="flex  items-center justify-between  rounded-md border-white p-6 mb-4 bg-black ">
          <p className="text-[14px] lg:text-[20px] text-white">
            5. Collaborate with another Discord community{" "}
          </p>
          <button className=" text-white  px-4 py-2  bg-purple border rounded-md lg:rounded-2xl lg:mr-4 text-[14px] lg:text-[15px]	 fw-400">
            Executed
          </button>
        </div>
        <div className="flex  items-center justify-between  rounded-md border-white p-6 mb-4 bg-black ">
          <p className="text-[14px] lg:text-[20px] text-white">
            6. Release new merchandise for the holiday season{" "}
          </p>
          <button className="  text-white px-4 py-2  bg-purple border rounded-md lg:rounded-2xl lg:mr-4 text-[14px] lg:text-[15px]	 fw-400">
            Executed
          </button>
        </div>

      </div>
        */}


    </div>
  );
}
