import { useEffect, useRef, useState } from "react";
import { useWallet} from "@solana/wallet-adapter-react";
//import { clusterApiUrl, Connection } from "@solana/web3.js";
import { IconMenu, IconX } from "../assets/icons";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import logo from "../assets/images/logo.png";
import "./Header/styles.css";
export const AdminHeader = () => {
  //const { connection } = useConnection();
  let wallet = useWallet();
  const walletModel = useWalletModal();
  //const [walletAddress, setWalletAdresss] = useState("");
  //const [Loading, setLoading] = useState(false);

  useEffect(() => {
    const onLoad = () => {
      checkIfWalletConnected();
    };
    window.addEventListener("load", onLoad);
    return () => window.removeEventListener("load", onLoad);
  }, [wallet]);
  const ref = useRef<HTMLElement | null>(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const out = (e: MouseEvent) => {
      if (ref.current && isActive && ref.current.contains(e.target as Node))
        return;
      setIsActive(false);
    };

    window.addEventListener("click", out);

    return () => window.removeEventListener("click", out);
  }, [isActive]);
  const checkIfWalletConnected = async () => {
    // debugger;
    try {
      //setLoading(true);
      if (wallet.connected && wallet.publicKey !== null) {
        //alert(wallet.publicKey.toString());
        //let wallet_public_address = "";
        //wallet_public_address = wallet.publicKey.toString();
        //setWalletAdresss(wallet_public_address);
       // console.log("public key", wallet.publicKey?.toString());
      }
    } catch (err) {
      // debugger;
      console.log(err);
    } finally {
      //setLoading(false);
    }
  };

  const connectWallet = async (e) => {
    e.preventDefault();
    walletModel.setVisible(true);


  };
  const disconnectWallet = async (e) => {
    e.preventDefault();
    try {
      wallet.disconnect();

      //setWalletAdresss("");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <header
      className="container flex items-center justify-between font-archivo font-base text-white pt-[25px] "
      ref={ref}
    >
      <div className="flex items-center gap-3 lg:gap-[50px] ">
        <Link to="/">
          <img src={logo} className="LOGO" alt="Phidao"/>
        </Link>
        <Link to="#"> Treasury</Link>
      </div>
      <nav className={classNames({ active: isActive })}>
        <ul className="flex items-center">
          <li>
            <Link to="/proposals">DAO</Link>
          </li>
          <li>
            <Link to="/leaderboard">Leaderboard</Link>
          </li>
          <li>
            <Link to="/playground">Playground</Link>
          </li>
          <li>
            <Link to="/admin/walletfund">Wallet</Link>
          </li>
          {/*<li>
            <Link to="#">Discourse</Link>
          </li>
          
          <li>
            <Link to="/admin/faucetlist">Faucets</Link>
          </li>
          <li>
            <Link to="/admin/tokenlist">Tokens</Link>
          </li>

          <li>
            <div className="CONNECT_BTN_WRAPPER block md:hidden">
              <button
                onClick={!wallet.connected ? connectWallet : disconnectWallet}
              >
                {!wallet.connected ? "Connect" : "Connected"}
              </button>
            </div>
          </li>*/}
        </ul>
        {/*<button
          className="block md:hidden absolute top-3 right-3"
          onClick={() => setIsActive((prev) => !prev)}
        >
          <IconX />
        </button>*/}
      </nav>

      <div>
        <div className="CONNECT_BTN_WRAPPER hidden md:block">
          <button
            onClick={!wallet.connected ? connectWallet : disconnectWallet}
          >
            {!wallet.connected ? "Connect" : "Connected"}
          </button>
        </div>
        <button
          className="MUNU_BTN  block md:hidden "
          onClick={() => setIsActive(true)}
        >
          <IconMenu />
        </button>
      </div>
      </header>
  );
};

//export default Header;
