import React, { useEffect, useState } from "react";
import './mint.css'

import auction_dummy from "../assets/images/Phigital.gif";

export const Mint = () => {

    const typeList = [
        { title: 'Type A', price: 1000 },
        { title: 'Type B', price: 1000 },
        { title: 'Type C', price: 1000 },
    ]

    const [typeValue, setTypeValue] = useState(0);
    const [quantityValue, setQuantityValue] = useState(1);
    const [priceValue, setPriceValue] = useState(0.050);

    const handleQuantityIncrement = () => {
        setQuantityValue(prevValue => prevValue + 1);
    };

    const handleQuantityDecrement = () => {
        if (quantityValue > 1) {
            setQuantityValue(prevValue => prevValue - 1);
        }
    };

    const handleQuantityChange = (e) => {
        const inputValue = parseInt(e.target.value) || 1;
        setQuantityValue(Math.max(inputValue, 1));
    };

    const handleTypeIncrement = () => {
        setTypeValue((prevValue) => (prevValue + 1) % typeList.length);
    };

    const handleTypeDecrement = () => {
        setTypeValue((prevValue) => (prevValue - 1 + typeList.length) % typeList.length);
    };


    useEffect(() => {
        //const selectedType = typeList[typeValue];
        //const totalPrice = (selectedType.price * quantityValue).toFixed(3);
        const totalPrice = parseFloat((typeList[typeValue].price * quantityValue).toFixed(3));
        setPriceValue(totalPrice);

    }, [typeValue, quantityValue]);

    return (
        <div id={'ft-mint'} className={'ft-mint'}>
            <div className={'ft-mint--grid'}>

                <div className="ft-mint--image">
                    <img src={auction_dummy} alt="" />
                </div>


                <div className="ft-mint--detail">

                    <div className="ft-mint--title">
                        <h1 className="ft-mint--title-h1">Phigital DAO</h1>
                    </div>

                    <div className="ft-mint--subtitle">
                        {`A TRANSFORMATIVE NFT COLLECTION THAT MERGES BASIC EGOS WITH POWERFUL TOOLS TO CREATE UNIQUE EVOLVED EGOS.`}
                    </div>

                    <div className="ft-mint--form">


                        <div className={'ft-mint--form-row-1'}>
                            <div>
                                <div className={'ft-mint--label'}>
                                    <span>TYPE</span>
                                </div>
                                <div className={'ft-mint--button-group'}>
                                    <button className={'ft-mint--button-type ft-mint--button-type--prev'} onClick={handleTypeDecrement}>{`<`}</button>
                                    <input className={'ft-mint--input'} type="text" value={typeList[typeValue].title}  disabled/>
                                    <button className={'ft-mint--button-type ft-mint--button-type--next'} onClick={handleTypeIncrement}>{`>`}</button>
                                </div>
                            </div>
                        </div>

                        <div className={'ft-mint--form-row-2'}>
                            <div>
                                <div className={'ft-mint--label'}>
                                    <span>QUANTITY</span>
                                </div>
                                <div className={'ft-mint--button-group'}>
                                    <button className={'ft-mint--button-qty ft-mint--button-qty--prev'} onClick={handleQuantityDecrement}>-</button>
                                    <input className={'ft-mint--input'} type="number" value={quantityValue} onChange={handleQuantityChange} disabled/>
                                    <button className={'ft-mint--button-qty ft-mint--button-qty--next'} onClick={handleQuantityIncrement}>+</button>
                                </div>
                            </div>

                            <div>
                                <div className={'ft-mint--label'}>
                                    <span>PRICE</span>
                                </div>
                                <div>
                                    <input className={'ft-mint--input'} type="text" value={`${priceValue} USD`} disabled />
                                </div>
                            </div>

                            <div>
                                <div>
                                    <button className={'ft-mint--btn'}>
                                        CONNECT
                                    </button>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="ft-mint--total">
                        <div className="ft-mint-hfi024d">
                            TOTAL MINTED
                        </div>
                        <div className="ft-mint-hfr354d">
                            <span className={'ft-mint-hfr354d--a1'}>150</span>
                            <span className={'ft-mint-hfr354d--a2'}>/</span>
                            <span className={'ft-mint-hfr354d--a3'}>1000</span>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
};
