import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from "react";

export const tokenContext = createContext<{
  tokenAddress: string;
  setTokenAddress: Dispatch<SetStateAction<string>>;
}>({
  tokenAddress: "",
  setTokenAddress: () => "",
});

const TokenProvider = ({ children }: { children: ReactNode }) => {
  const [tokenAddress, setTokenAddress] = useState<string>("");

  return (
    <tokenContext.Provider value={{ tokenAddress, setTokenAddress }}>
      {children}
    </tokenContext.Provider>
  );
};

export default TokenProvider;
