import React, { useState } from "react";
import { FormControl } from "@mui/material";
import { InputField } from "../../components/CutomUiFields";
import { useForm } from "react-hook-form";
import { loginSchema } from "../../utils/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

type FormValues = {
  user_email: string;
  password: string;
};

export const Login = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(loginSchema),
  });

  const handleClose = () => {
    setIsModalOpen(false);
    navigate("/admin/tokenlist");
  };

  const objectToFormData = (data) => {
    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    return formData;
  };

  {/*const postToken = async (data) => {
    const formData = objectToFormData(data);

    const requestOptions = {
      method: "POST",
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        authorization: process.env.REACT_APP_Token as string,
      },
      body: formData,
    };

    const res = await fetch(
      `${process.env.REACT_APP_URL}/api/mintSBCollection`,
      requestOptions
    );

    return res.json();
  };*/}

  const onSubmit = async (data) => {
    //console.log(data.token_max_supply);
    try {
      const formValues = {
        ...data,
      };
      if (process.env.REACT_APP_USER_EMAIL as string == data.user_email && process.env.REACT_APP_PASSWORD as string == data.password) {
        localStorage.setItem("allowed", "1");
        navigate("/admin/tokenlist");
      } else {
        localStorage.setItem("allowed", "0");
        toast.error("User name and Password does not exist.");
      }


    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className="create_token">

          <div className={'container container-form section-container-form'}>
            <div className="ft-form-container">
              <div className="ft-bg"></div>
              <form onSubmit={handleSubmit(onSubmit)}>

                <div className="ft-form">

                  <div className={'ft-form-title'}>
                    Login
                  </div>

                  <FormControl fullWidth className="mb-4">
                    <InputField
                      fullWidth
                      id="standard-basic"
                      label="Email"
                      variant="standard"
                      {...register("user_email")}
                      error={!!errors.user_email}
                      helperText={errors.user_email?.message}
                    />
                  </FormControl>

                  <FormControl fullWidth className="mb-4">
                    <InputField
                      fullWidth
                      id="standard-basic"
                      label="Password"
                      type="password"
                      variant="standard"
                      {...register("password")}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                    />
                  </FormControl>

                  <div className="">
                    <button className="ft-button w-full" data-component-style={"1"}>
                      SIGN IN
                    </button>
                  </div>

                </div>

              </form>
            </div>
          </div>


      </section>


      {/*
      <section className="create_token">
        <div className="container">
          <div className="grid grid-cols-1  text-white  ">
            <p className="text-[25px] mt-6 font-bold text-white">
              Login
            </p>

          </div>


          <form onSubmit={handleSubmit(onSubmit)}>
            <div className=" px-9 py-5 border rounded-md  mt-[50px] text-white">
              <FormControl fullWidth className="mb-4">
                <InputField
                  fullWidth
                  id="standard-basic"
                  label="Email"
                  variant="standard"
                  {...register("user_email")}
                  error={!!errors.user_email}
                  helperText={errors.user_email?.message}
                />
              </FormControl>

              <FormControl fullWidth className="mb-4">
                <InputField
                  fullWidth
                  id="standard-basic"
                  label="Password"
                  type="password"
                  variant="standard"
                  {...register("password")}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                />
              </FormControl>

            </div>
            <div className="grid grid-cols-1  ml-[20px] mr-[30px]   lg:grid-cols-1 lg:mx-[150px] ">
              <button className="  mt-4  px-3 py-2  bg-btnbg border rounded lg:rounded-3xl  text-[18px] lg:text-[18px]	 fw-400">
                Login
              </button>
            </div>
          </form>


        </div>
      </section>
      */}



      {/*<Dialog onClose={() => setIsModalOpen(false)} open={isModalOpen}>
        <div className="p-5">
          <h2 className="mb-4">Token Generated</h2>
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Dialog>*/}
    </>
  );
};
