import { FormControl, MenuItem, Select } from "@mui/material";

const BlackSelect = ({ value, data, handleChange, label, name, error }) => {
  return (
    <>
      <div className="mb-4 ft-form-select-container">
        <FormControl
          fullWidth
          sx={{
            color: "white",
            /*background: "black",*/
          }}
        >
          <label htmlFor="demo-select-small" id={"demo-select-small-label"}>{label}</label>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            className={'ft-form-select--bg'}
            name={name}
            value={value}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            onChange={handleChange}
            renderValue={(value) => {
              return (
                <div className="w-full ">
                  {/*<span className="block text-[10px] text-lightgray">
                    {label}
                  </span>*/}
                  <span>{value.value || "Select...."}</span>
                </div>
              );
            }}
            sx={{
              color: "white",
              boxShadow: "none",

              "& .MuiOutlinedInput-notchedOutline": {
                border: "none !important",
                boxShadow: "none",
              },
              "& .MuiSelect-icon ": {
                color: "white",
              },
              "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
              {
                paddingBlock: "1.5px",
              },
            }}
          >
            {data.map((el) => (
              <MenuItem key={el.id} value={el}>
                {el.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {error && (
        <p style={{ color: "red", fontSize: "12px" }} className="mt-1">
          {error}
        </p>
      )}
    </>
  );
};

export default BlackSelect;
