import React from 'react';
import './FtBlockInfo.css';

interface FtBlockInfoProps {
    title: string;
    label: string;
    value: string;
    icon?: string;
}

const FtBlockInfo: React.FC<FtBlockInfoProps> = ({ title, label, value, icon }) => {
    const renderIcon = () => {
        switch (icon) {
            case 'search':
                return (

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                );

            default:
                return null;
        }
    };

    return (
        <div className="ft-block-info">
            <div className="ft-bg"></div>

            <div className="ft-block-info-flex">
                <div className="ft-block-info--header">
                    <div className="ft-block-info--header--title">{title}</div>
                </div>
                <div className="ft-block-info--content">
                    <div className="ft-block-info--label">{label}</div>
                    <div className="ft-block-info--value-icon">
                        <div  className="ft-block-info--value">{value}</div>
                        { renderIcon()
                            && (
                                <div className="ft-block-info--icon">
                                    {renderIcon()}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FtBlockInfo;
