import React, { FC, useEffect, useState, useMemo } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import playgroundService from "../services/playground";
import EventEmitter from "../utils/eventService";
export interface CategoryViewProps {
  traitList: string[];
  categoryList: string[];
  bannedHairsList: string[];
  bannedShoesList: string[];
  getTraitImage: (type: any, directoryName: any, imgId: any) => void;
  startLoading: (type: boolean) => void;
}
export const Traitcategories: FC<CategoryViewProps> = ({ traitList, categoryList, bannedHairsList, bannedShoesList, getTraitImage, startLoading }) => {
  //debugger;
  let skins = ["Dark.png", "Light.png", "Tan.png"];
  let selectedBody: string = "Dark.png";
  let srchCategory: string = "";
  let accessoriesRand: string = "";
  let backgroundRand: string = "";
  let bodyRand: string = "";
  let bottomsRand: string = "";
  let armsRand: string = "";
  let hairRand: string = "";
  let hand_propsRand: string = "";
  let headRand: string = "";
  let platformRand: string = "";
  let shoesRand: string = "";
  let topsRand: string = "";
  let accessoriesArrParams: string[] = [];
  let backgroundArrParams: string[] = [];
  let bodyArrParams: string[] = [];
  let bottomsArrParams: string[] = [];
  let armsArrParams: string[] = [];
  let hairArrParams: string[] = [];
  let hand_propsArrParams: string[] = [];
  let headArrParams: string[] = [];
  let platformArrParams: string[] = [];
  let shoesArrParams: string[] = [];
  let topArrParams: string[] = [];
  let armsOriginalArrWithoutState: any;
  let headOriginalArrWithoutState: any;
  let hairOriginalArrWithoutState: any[] = [];
  let shoeOriginalArrWithoutState: any[] = [];
  const [accessoriesArr, setAccessoriesArray] = useState([]);
  const [backgroundArr, setBackgroundArray] = useState([]);
  const [bodyArr, setBodyArray] = useState([]);
  const [bottomsArr, setBottomArray] = useState([]);
  const [armsArr, setArmsArray] = useState([]);
  const [hairArr, setHairArray] = useState([] as any);
  const [hand_propsArr, setHandPropsArray] = useState([]);
  const [headArr, setHeadArray] = useState([]);
  const [platformArr, setPlatformArray] = useState([]);
  const [shoesArr, setShoesArray] = useState([] as any);
  const [topArr, setTopArray] = useState([]);

  const [armsOriginalArr, setArmsOriginalArray] = useState([]);
  const [headOriginalArr, setHeadOriginalArray] = useState([]);
  const [hairOriginalArr, setHairOriginalArray] = useState([]);
  const [shoeOriginalArr, setShoeOriginalArray] = useState([]);
  const [category, setCategory] = useState("Male");
  const [accessories, setAccessories] = useState("");
  const [showAccessories, setShowAccessories] = useState("");
  const [background, setBackground] = useState("");
  const [showBackground, setShowBackground] = useState("");
  const [body, setBody] = useState("");
  const [showBody, setShowBody] = useState("");
  const [bottoms, setBottoms] = useState("");
  const [showBottoms, setShowBottom] = useState("");
  const [arms, setArms] = useState("");
  const [hair, setHair] = useState("");
  const [showHair, setShowHair] = useState("");
  const [hand_props, setHandProps] = useState("");
  const [showHand_props, setShowHandProps] = useState("");
  const [head, setHead] = useState("");
  const [showHead, setShowHead] = useState("");
  const [platform, setPlatform] = useState("");
  const [showPlatform, setShowPlatform] = useState("");
  const [shoes, setShoes] = useState("");
  const [showShoes, setShowShoes] = useState("");
  const [tops, setTops] = useState("");
  const [showTops, setShowTops] = useState("");
  const [imgId, setImageId] = useState("57");
  let noneText = "None";

  useEffect(() => {
    //debugger;
    //console.log(bannedHairsList);
    //if (categoryList.length > 0) {
    if (traitList.length > 0) {
      debugger;
      initialize();
    }

    //}
    const feelHungryMethod = () => {
      let btnObj: any = document.getElementById("btnFeelLucky");
      btnObj.click();
    }
    const generateImageMethod = () => {
      let btnObj: any = document.getElementById("btnGenerateNft");
      btnObj.click();
    }
    const listenerHungry = EventEmitter.addListener('feelHungryListener', feelHungryMethod);
    const listenerNft = EventEmitter.addListener('generateImageListener', generateImageMethod);
    return () => {
      //debugger;
      listenerHungry.remove();
      listenerNft.remove();
    }
  }, [traitList]);
  async function initialize() {
    debugger;
    var randIndex = settingRandomValues(skins);
    selectedBody = skins[randIndex];
    //setShowBody(excludePng(selectedBody));
    randIndex = settingRandomValues(categoryList);
    srchCategory = categoryList[randIndex];
    setCategory(srchCategory);
    //debugger;
    await filterTraits(srchCategory).then((res) => {
      debugger;
      generateRandNft(srchCategory);
    });
  }
  async function filterTraits(categoryToFilter: any) {
    //debugger;
    traitList.map((obj: any) => {
      if (obj.category == categoryToFilter) {
        if (obj.directory == "accessories") {
          setShowAccessories(excludePng(obj.traits[0]));
          setAccessoriesArray(obj.traits);
          accessoriesArrParams = obj.traits;
          setAccessories(obj.traits[0]);
        }

        else if (obj.directory == "background") {
          setShowBackground(excludePng(obj.traits[0]));
          setBackground(obj.traits[0]);
          setBackgroundArray(obj.traits);
          backgroundArrParams = obj.traits;
        }
        else if (obj.directory == "arms") {

          let returnedObjArray = obj.traits.filter(filterArms);
          //debugger;
          setArmsArray(returnedObjArray);
          setArmsOriginalArray(obj.traits);
          armsOriginalArrWithoutState = obj.traits;

          setArms(returnedObjArray[0]);
          armsArrParams = returnedObjArray;

        }


        else if (obj.directory == "head") {
          let returnedObjArray = obj.traits.filter(filterHead);
          setHeadArray(returnedObjArray);
          setHead(returnedObjArray[0]);

          setShowHead(excludePng(returnedObjArray[0]));
          setHeadOriginalArray(obj.traits);
          headOriginalArrWithoutState = obj.traits;
          headArrParams = returnedObjArray;
          //debugger;
          filterHair(formateHead(excludePng(returnedObjArray[0])), obj.category);
        }
        else if (obj.directory == "hair") {
          //debugger;
          hairOriginalArrWithoutState = obj.traits;
          setHairOriginalArray(obj.traits);
        }
        else if (obj.directory == "bottoms") {
          //debugger;
          setShowBottom(excludePng(obj.traits[0]));
          setBottoms(obj.traits[0]);
          setBottomArray(obj.traits);
          bottomsArrParams = obj.traits;

        }
        else if (obj.directory == "shoes") {
          //debugger;
          //setShowShoes(excludePng(obj.traits[0]));
          //setShoes(obj.traits[0]);
          //setShoesArray(obj.traits);
          //shoesArrParams = obj.traits;
          //debugger;
          shoeOriginalArrWithoutState = obj.traits;
          setShoeOriginalArray(obj.traits);
          filterShoes(excludePng(bottomsArrParams[0]), obj.category);
        }
        else if (obj.directory == "hand_props") {
          setShowHandProps(excludePng(obj.traits[0]));
          setHandProps(obj.traits[0]);
          setHandPropsArray(obj.traits);
          hand_propsArrParams = obj.traits;
        }
        else if (obj.directory == "platform") {
          setShowPlatform(excludePng(obj.traits[0]));
          setPlatform(obj.traits[0]);
          setPlatformArray(obj.traits);
          platformArrParams = obj.traits;
        }

        else if (obj.directory == "tops") {
          setShowTops(excludePng(obj.traits[0]));
          setTops(obj.traits[0]);
          setTopArray(obj.traits);
          topArrParams = obj.traits;
        }

        else if (obj.directory == "body") {
          setShowBody(excludePng(obj.traits[0]));
          setBody(obj.traits[0]);
          setBodyArray(obj.traits);
          bodyArrParams = obj.traits;
        }
      }

    });

  }
  const filterHead = (e: string) => {
    if (selectedBody.includes("Dark")) {
      if (e.includes("Dark")) {
        return e;
      }
    } else if (selectedBody.includes("Light")) {
      if (e.includes("Light")) {
        return e;
      }
    } else if (selectedBody.includes("Tan")) {
      if (e.includes("Tan")) {
        return e;
      }
    }
  }
  const filterHair = (selectedHead: string, srchCategory: string) => {
    if (srchCategory == "Female") {
      //debugger;
      let filteredHairs: any[] = [];
      if (hairOriginalArrWithoutState.length == 0 || hairOriginalArrWithoutState == undefined) {
        hairOriginalArrWithoutState = hairOriginalArr;
        //debugger;
      }
      hairOriginalArrWithoutState.forEach(element => {
        if (!bannedHairsList[0][selectedHead].includes(element)) {
          filteredHairs.push(element);
        }
      });

      setShowHair(excludePng(filteredHairs[0]));
      setHair(filteredHairs[0]);
      setHairArray(filteredHairs);
      hairArrParams = filteredHairs;
    } else {
      if (hairOriginalArrWithoutState.length == 0 || hairOriginalArrWithoutState == undefined) {
        hairOriginalArrWithoutState = hairOriginalArr;
        //debugger;
      }
      setShowHair(excludePng(hairOriginalArrWithoutState[0]));
      setHair(hairOriginalArrWithoutState[0]);
      setHairArray(hairOriginalArrWithoutState);
      hairArrParams = hairOriginalArrWithoutState;
    }


    //debugger;
  }
  const filterShoes = (selectedBottom: string, srchCategory: string) => {
    if (srchCategory == "Female") {
      //debugger;
      let filteredShoes: any[] = [];
      if (shoeOriginalArrWithoutState.length == 0 || shoeOriginalArrWithoutState == undefined) {
        shoeOriginalArrWithoutState = shoeOriginalArr;
        //debugger;
      }
      //debugger;
      shoeOriginalArrWithoutState.forEach(element => {

        if (!bannedShoesList[0][selectedBottom].includes(element)) {
          filteredShoes.push(element);
        }


      });

      setShowShoes(excludePng(filteredShoes[0]));
      setShoes(filteredShoes[0]);
      setShoesArray(filteredShoes);
      shoesArrParams = filteredShoes;
      //debugger;
    } else {
      if (shoeOriginalArrWithoutState.length == 0 || shoeOriginalArrWithoutState == undefined) {
        shoeOriginalArrWithoutState = shoeOriginalArr;
        //debugger;
      }
      setShowShoes(excludePng(shoeOriginalArrWithoutState[0]));
      setShoes(shoeOriginalArrWithoutState[0]);
      setShoesArray(shoeOriginalArrWithoutState);
      shoesArrParams = shoeOriginalArrWithoutState;
    }


    //debugger;
  }
  const filterArms = (e: string) => {
    //debugger;
    if (selectedBody.includes("Dark")) {
      if (e.includes("Dark")) {
        return e;
      }
    } else if (selectedBody.includes("Light")) {
      if (e.includes("Light")) {
        return e;
      }
    } else if (selectedBody.includes("Tan")) {
      if (e.includes("Tan")) {
        return e;
      }
    }
  }
  const onChangeCategory = (e: any) => {
    if (e !== "") {
      srchCategory = e;
      setCategory(e);
      filterTraits(e);
    }
    else {
      setCategory(noneText);
    }
  }
  const onChangeAccessories = (e: any) => {
    if (e !== "") {
      setShowAccessories(excludePng(e));
      setAccessories(e);
    }
    else {
      setShowAccessories(noneText);
      setAccessories(noneText);
    }
  }
  const onChangeBackground = (e: any) => {
    if (e !== "") {
      setBackground(e);
      setShowBackground(excludePng(e));
    }
    else {
      setShowBackground(noneText);
      setBackground(noneText);
    }
  }
  const onChangeBody = (e: any) => {
    setBodyChangeArr(e);
  }

  const onChangeBottoms = (e: any) => {
    if (e !== "") {
      setBottoms(e);
      setShowBottom(excludePng(e));
      filterShoes(excludePng(e), category);
    }
    else {
      setBottoms(noneText);
      setShowBottom(noneText);
    }
  }
  const onChangeHead = (e: any) => {
    if (e !== "") {
      setHead(e);
      setShowHead(excludePng(e));
      //debugger;
      filterHair(formateHead(excludePng(e)), category);
      //debugger;
    }
    else {
      setHead(noneText);
      setShowHead(noneText);
    }
  }
  const onChangeHair = (e: any) => {
    if (e !== "") {
      setHair(e);
      setShowHair(excludePng(e));
    }
    else {
      setHair(noneText);
      setShowHair(noneText);
    }
  }
  const onChangeHandProps = (e: any) => {
    if (e !== "") {
      setHandProps(e);
      setShowHandProps(excludePng(e));
    } else {
      setHandProps(noneText);
      setShowHandProps(noneText);
    }

  }
  const onChangePlatform = (e: any) => {
    if (e !== "") {
      setPlatform(e);
      setShowPlatform(excludePng(e));
    }
    else {
      setPlatform(noneText);
      setShowPlatform(noneText);
    }
  }
  const onChangeShoes = (e: any) => {
    if (e !== "") {
      setShoes(e);
      setShowShoes(excludePng(e));
    }
    else {
      setShoes(noneText);
      setShowShoes(noneText);
    }
  }
  const onChangeTops = (e: any) => {
    if (e !== "") {
      setTops(e);
      setShowTops(excludePng(e));
    }
    else {
      setTops(noneText);
      setShowTops(noneText);
    }
  }

  const onClickGenerateArtBeta = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    //debugger;

    srchCategory = category;
    //implPropsAndAccessoriesCheck();
    let returnObj = implPropsAndAccessoriesCheck();
    if (returnObj == 1) {
      debugger;
      generateNftImage(background,
        platform, arms, "", body,
        head, bottoms, tops, shoes, hair,
        accessories);
    } else {
      debugger;
      generateNftImage(background,
        platform, arms, hand_props, body,
        head, bottoms, tops, shoes, hair,
        accessories);
    }
  }

  const onClickLuckySubmitBeta = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    accessoriesArrParams = accessoriesArr;
    backgroundArrParams = backgroundArr;
    bodyArrParams = bodyArr;
    bottomsArrParams = bottomsArr;
    armsArrParams = armsArr;
    hairArrParams = hairArr;
    hand_propsArrParams = hand_propsArr;
    headArrParams = headArr;
    platformArrParams = platformArr;
    shoesArrParams = shoesArr;
    topArrParams = topArr;
    initialize();
  };

  function settingRandomValues(objArray: any) {
    var min = 0;
    var rand = Math.floor(min + (Math.random() * (objArray.length - min)));
    return rand;
    //debugger;
  }
  const setBodyChangeArr = (param: string) => {
    if (param !== "") {
      selectedBody = param;
      setBody(param);
      setShowBody(excludePng(param));
      let returnedHeadObjArray: any;
      let returnedArmsObjArray: any;
      if (headOriginalArrWithoutState !== undefined && headOriginalArrWithoutState.length > 0) {
        returnedHeadObjArray = headOriginalArrWithoutState.filter(filterHead);
      } else {
        returnedHeadObjArray = headOriginalArr.filter(filterHead);
      }
      setHeadArray(returnedHeadObjArray);
      headArrParams = returnedHeadObjArray;
      setHead(returnedHeadObjArray[0]);
      setShowHead(excludePng(returnedHeadObjArray[0]));
      if (armsOriginalArrWithoutState !== undefined && armsOriginalArrWithoutState.length > 0) {
        returnedArmsObjArray = armsOriginalArrWithoutState.filter(filterArms);
      } else {
        returnedArmsObjArray = armsOriginalArr.filter(filterArms);
      }
      setArmsArray(returnedArmsObjArray);
      armsArrParams = returnedArmsObjArray;
      setArms(returnedArmsObjArray[0]);
    }
    else {
      setBody(noneText);
      setShowBody(noneText);
    }
  }
  async function generateRandNft(srchCategory) {
    if (traitList.length > 0) {
      //"head,arms,body"
      //debugger;
      randIndex = settingRandomValues(bodyArrParams);
      bodyRand = bodyArrParams[randIndex];
      //debugger;
      setBodyChangeArr(bodyRand);
      //debugger;
      var randIndex = settingRandomValues(accessoriesArrParams);
      accessoriesRand = accessoriesArrParams[randIndex];
      setAccessories(accessoriesRand);
      //debugger;
      setShowAccessories(excludePng(accessoriesRand));
      randIndex = settingRandomValues(backgroundArrParams);
      backgroundRand = backgroundArrParams[randIndex];
      setBackground(backgroundRand);
      setShowBackground(backgroundRand);
      //debugger;
      randIndex = settingRandomValues(armsArrParams);
      armsRand = armsArrParams[randIndex];
      setArms(armsRand);
      //debugger;
      randIndex = settingRandomValues(bottomsArrParams);
      bottomsRand = bottomsArrParams[randIndex];
      setBottoms(bottomsRand);
      setShowBottom(excludePng(bottomsRand));
      filterShoes(excludePng(bottomsRand), srchCategory);
      randIndex = settingRandomValues(headArrParams);
      headRand = headArrParams[randIndex];
      setHead(headRand);
      setShowHead(excludePng(headRand));
      //debugger;
      filterHair(formateHead(excludePng(headRand)), srchCategory);
      //debugger;
      randIndex = settingRandomValues(hairArrParams);
      hairRand = hairArrParams[randIndex];
      setHair(hairRand);
      setShowHair(excludePng(hairRand));
      randIndex = settingRandomValues(hand_propsArrParams);
      hand_propsRand = hand_propsArrParams[randIndex];
      setHandProps(hand_propsRand);
      setShowHandProps(excludePng(hand_propsRand));
      randIndex = settingRandomValues(platformArrParams);
      platformRand = platformArrParams[randIndex];
      setPlatform(platformRand);
      setShowPlatform(excludePng(platformRand));
      randIndex = settingRandomValues(shoesArrParams);
      shoesRand = shoesArrParams[randIndex];
      setShoes(shoesRand);
      setShowShoes(excludePng(shoesRand));
      randIndex = settingRandomValues(topArrParams);
      topsRand = topArrParams[randIndex];
      setTops(topsRand);
      setShowTops(excludePng(topsRand));
      //debugger;

      //debugger;
      let returnObj = implPropsAndAccessoriesCheck();
      if (returnObj == 1) {
        debugger;
        await generateNftImage(backgroundRand,
          platformRand, armsRand, "", bodyRand,
          headRand, bottomsRand, topsRand, shoesRand, hairRand,
          accessoriesRand);
      } else {
        debugger;
        await generateNftImage(backgroundRand,
          platformRand, armsRand, hand_propsRand, bodyRand,
          headRand, bottomsRand, topsRand, shoesRand, hairRand,
          accessoriesRand);
      }

    }
  }
  function implPropsAndAccessoriesCheck() {
    //debugger;
    let found = 0;
    if (accessories == "Pens.png" && hand_props == "Phone.png") {
      setHandProps(noneText);
      setShowHandProps(noneText);
      found = 1;
    }
    else if (accessories == "Pens.png" && hand_props == "Headphones.png") {
      setHandProps(noneText);
      setShowHandProps(noneText);
      found = 1;
    }
    return found;
  }
  async function generateNftImage(backgroundParam: string,
    platformParam: string, armsParam: string, hand_propsParam: string, bodyParam: string,
    headParam: string, bottomsParam: string, topsParam: string, shoesParam: string, hairParam: string,
    accessoriesParam: string) {//debugger;
    var min = 1;
    var max = 1000000;
    var rand = Math.floor(min + (Math.random() * (max - min)));
    setImageId(rand.toString());
    localStorage.setItem("imgId", rand.toString());
    //debugger;
    if (localStorage.getItem("imgId") !== null && localStorage.getItem("imgId") !== undefined) {
      //debugger;
      //startLoading(true);
      EventEmitter.emit('startStopLoading', { params: '1' });
      var imgIdStorage = localStorage.getItem("imgId");
      debugger;
      const result = await playgroundService.generateImage(
        "1",
        srchCategory,
        backgroundParam,
        platformParam, armsParam, hand_propsParam, bodyParam,
        headParam, bottomsParam, topsParam, shoesParam, hairParam,
        accessoriesParam, imgIdStorage, "1000", "1000"
      ).then((res) => {
        // debugger;
        //startLoading(false);
        EventEmitter.emit('startStopLoading', { params: '0' });
        getTraitImage(srchCategory, 'assets_playground', imgIdStorage);
      })
        .catch((e) => {
          //debugger;
          EventEmitter.emit('startStopLoading', { params: '0' });
          //startLoading(false);
          console.log(e.message)
        })
    } else {
      EventEmitter.emit('startStopLoading', { params: '0' });
      //startLoading(false);
    }
  }
  function formateHead(item: string): string {
    //debugger;
    let returnedItem = "";
    if (item !== undefined && item != "") {
      if (item.includes("Dark")) {
        returnedItem = item.replace("Dark", "");
        //debugger;
      } else if (item.includes("Light")) {
        returnedItem = item.replace("Light", "");
      } else if (item.includes("Tan")) {
        returnedItem = item.replace("Tan", "");
      }
    }
    //debugger;

    return returnedItem.trim();
  }
  function excludePng(item: string): string {
    if (item !== undefined && item !== "") {
      //debugger;
      return item.substring(0, item.length - 4)
    }
    else {
      return ""
    }
  }
  return (

    <div className="min-w-[260px] lg:w-[400px]">

      {<Button onClick={onClickGenerateArtBeta} id="btnGenerateNft" className="w-[95%] bg-btnbg pb-3 pt-3 hover:bg-btnbg rounded-none flex justify-center items-center text-center border-gradient button-display-none">
        Generate Art
      </Button>}

      <Dropdown className="my-3" onSelect={onChangeCategory}>
        <Dropdown.Toggle
          className="p-0 border-gradient w-[95%] hover:bg-btnbg rounded-none flex justify-start items-center  "
          id=""
        >
          <div className="bg-black px-[12px] py-[6px] w-100">
            <div className="w-[100%]">
              <p className="text-[9px] lg:text-left  text-lightgray">
                Type
              </p>
              <p className="lg:text-left ">{category}</p>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {categoryList.map(item => (
            <Dropdown.Item eventKey={item} key={item}>
              {item}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="my-3" onSelect={onChangeBody}>
        <Dropdown.Toggle
          className="p-0 border-gradient w-[95%] hover:bg-btnbg rounded-none flex justify-start items-center  "
          id=""
        >
          <div className="bg-black px-[12px] py-[6px] w-100">
            <div className="w-[100%]">
              <p className="text-[9px] lg:text-left  text-lightgray">
                Skin
              </p>
              <p className="lg:text-left ">{showBody}</p>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {bodyArr.map(item => (
            <Dropdown.Item eventKey={item} key={item}>
              {excludePng(item)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="my-3" onSelect={onChangeHead}>
        <Dropdown.Toggle
          className="p-0 border-gradient w-[95%] hover:bg-btnbg rounded-none flex justify-start items-center  "
          id=""
        >
          <div className="bg-black px-[12px] py-[6px] w-100">
            <div className="w-[100%]">
              <p className="text-[9px] lg:text-left  text-lightgray">
                Face
              </p>
              <p className="lg:text-left ">{formateHead(showHead)}</p>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {headArr.map(item => (
            <Dropdown.Item eventKey={item} key={item}>
              {formateHead(excludePng(item))}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="my-3" onSelect={onChangeHair}>
        <Dropdown.Toggle
          className="p-0 border-gradient w-[95%] hover:bg-btnbg rounded-none flex justify-start items-center  "
          id=""
        >
          <div className="bg-black px-[12px] py-[6px] w-100">
            <div className="w-[100%]">
              <p className="text-[9px] lg:text-left  text-lightgray">
                Hair
              </p>
              <p className="lg:text-left ">{showHair}</p>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown_height">
          {hairArr.map(item => (
            <Dropdown.Item eventKey={item} key={item}>
              {excludePng(item)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="my-3" onSelect={onChangeTops}>
        <Dropdown.Toggle
          className="p-0 border-gradient w-[95%] hover:bg-btnbg rounded-none flex justify-start items-center  "
          id=""
        >
          <div className="bg-black px-[12px] py-[6px] w-100">
            <div className="w-[100%]">
              <p className="text-[9px] lg:text-left  text-lightgray">
                Tops
              </p>
              <p className="lg:text-left ">{showTops}</p>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {topArr.map(item => (
            <Dropdown.Item eventKey={item} key={item}>
              {excludePng(item)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="my-3" onSelect={onChangeBottoms}>
        <Dropdown.Toggle
          className="p-0 border-gradient w-[95%] hover:bg-btnbg rounded-none flex justify-start items-center  "
          id=""
        >
          <div className="bg-black px-[12px] py-[6px] w-100">
            <div className="w-[100%]">
              <p className="text-[9px] lg:text-left  text-lightgray">
                Bottoms
              </p>
              <p className="lg:text-left ">{showBottoms}</p>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {bottomsArr.map(item => (
            <Dropdown.Item eventKey={item} key={item}>
              {excludePng(item)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="my-3" onSelect={onChangeShoes}>
        <Dropdown.Toggle
          className="p-0 border-gradient w-[95%] hover:bg-btnbg rounded-none flex justify-start items-center  "
          id=""
        >
          <div className="bg-black px-[12px] py-[6px] w-100">
            <div className="w-[100%]">
              <p className="text-[9px] lg:text-left  text-lightgray">
                Shoes
              </p>
              <p className="lg:text-left ">{showShoes}</p>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {shoesArr.map(item => (
            <Dropdown.Item eventKey={item} key={item}>
              {excludePng(item)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="my-3" onSelect={onChangeAccessories}>
        <Dropdown.Toggle
          className="p-0 border-gradient w-[95%] hover:bg-btnbg rounded-none flex justify-start items-center  "
          id=""
        >
          <div className="bg-black px-[12px] py-[6px] w-100">
            <div className="w-[100%]">
              <p className="text-[9px] lg:text-left  text-lightgray">
                Accessories
              </p>
              <p className="lg:text-left ">{showAccessories}</p>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {accessoriesArr.map(item => (
            <Dropdown.Item eventKey={item} key={item}>
              {excludePng(item)}
            </Dropdown.Item>
          ))}
          <Dropdown.Item eventKey="" key="">
            None
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="my-3" onSelect={onChangeHandProps}>
        <Dropdown.Toggle
          className="p-0 border-gradient w-[95%] hover:bg-btnbg rounded-none flex justify-start items-center  "
          id=""
        >
          <div className="bg-black px-[12px] py-[6px] w-100">
            <div className="w-[100%]">
              <p className="text-[9px] lg:text-left  text-lightgray">
                Props
              </p>
              <p className="lg:text-left ">{showHand_props}</p>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {hand_propsArr.map(item => (
            <Dropdown.Item eventKey={item} key={item}>
              {excludePng(item)}
            </Dropdown.Item>
          ))}
          <Dropdown.Item eventKey="" key="">
            None
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="my-3" onSelect={onChangePlatform}>
        <Dropdown.Toggle
          className="p-0 border-gradient w-[95%] hover:bg-btnbg rounded-none flex justify-start items-center  "
          id=""
        >
          <div className="bg-black px-[12px] py-[6px] w-100">
            <div className="w-[100%]">
              <p className="text-[9px] lg:text-left  text-lightgray">
                Platform
              </p>
              <p className="lg:text-left ">{showPlatform}</p>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {platformArr.map(item => (
            <Dropdown.Item eventKey={item} key={item}>
              {excludePng(item)}
            </Dropdown.Item>
          ))}
          <Dropdown.Item eventKey="" key="">
            None
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="my-3" onSelect={onChangeBackground}>
        <Dropdown.Toggle
          className="p-0 border-gradient w-[95%] hover:bg-btnbg rounded-none flex justify-start items-center  "
          id=""
        >
          <div className="bg-black px-[12px] py-[6px] w-100">
            <div className="w-[100%]">
              <p className="text-[9px] lg:text-left  text-lightgray">
                Background
              </p>
              <p className="lg:text-left ">{showBackground}</p>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {backgroundArr.map(item => (
            <Dropdown.Item eventKey={item} key={item}>
              {excludePng(item)}
            </Dropdown.Item>
          ))}
          <Dropdown.Item eventKey="" key="">
            None
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Button onClick={onClickLuckySubmitBeta} id="btnFeelLucky" className="w-[95%] bg-btnbg pb-3 pt-3 hover:bg-btnbg border rounded-md border-white flex justify-start items-center button-display-none">
        I'm feeling Lucky
      </Button>
      <Button onClick={onClickGenerateArtBeta} id="btnGenerateNft" className="w-[95%] bg-btnbg pb-3 pt-3 hover:bg-btnbg rounded-none flex justify-center items-center text-center border-gradient">
        Generate
      </Button>
    </div>


  );
}
