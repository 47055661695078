import React, { useEffect, useState, useContext } from "react";
//import { LeaderboardItems } from "./data";
import { LeaderboardItem } from "./LeaderboardItem";

export const LeaderboardMonthlyList = ({ title }: { title?: string }) => {
  const [LeaderboardItems, setBoardItems] = useState<any[]>([]);
  const [isLeader, setIsLeader] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        console.log("REACT_APP_PORTAL_URL", process.env.REACT_APP_URL);
        const requestOptions = {
          method: "POST",
          headers: {
            authorization: process.env.REACT_APP_Token as string,
          },
        };
        const res = await fetch(
          `${process.env.REACT_APP_URL}/api/topLeaders`,
          requestOptions
        );
        const { data, statusCode } = await res.json();
        if (statusCode == 200) {
          console.log(data);
          if (data.leadBoardLeftList.length > 0) {
            setBoardItems(data.leadBoardLeftList);
            //debugger;
          }
          else {
            setIsLeader(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  return (
    <div className="BOARD-LIST_WRAPPER ">
      {title && <Title title={title} />}
      <div className="flex items-center justify-between text-[#99A5B9] text-sm lg:text-xl  mb-4">
        <div className="flex items-center  gap-9">
          <span>Rank</span>
          <span>Active Components</span>
        </div>
        <span>Comments</span>
      </div>
      <div className="font-[Archivo] text-white">
        {LeaderboardItems.map((item, i) => (
          <LeaderboardItem key={i} index={i + 1} item={item} />
        ))}
      </div>
    </div>
  );
};

const Title = ({ title }) => {
  const titleNods = title.split(" ");

  return (
    <h4 className=" TEXT_GRADIANT LEADERBOARD-LIST_TITLE uppercase space-x-4 text-white text-center md:text-left">
      <span className="uppercase">{titleNods[0]}</span>
      <span>{titleNods[1]}</span>
    </h4>
  );
};
