import "./styles.css";
import { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import classNames from "classnames";
export const Footer = () => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const adminLogin = async (e:any) => {
    e.preventDefault();
    
    navigate("/login");
  };
  const adminLogout = async (e:any) => {
    e.preventDefault();
    try {
      localStorage.setItem("allowed", "0");
      navigate("/");
      //setWalletAdresss("");
    } catch (err) {
      console.log(err);
    }
  };
  const { openAuthPopup, discordLogout, discordToken } =
    useContext(AuthContext);
  return (
    <footer className="py-8 md:py-10 bg-black">
      <div className="container flex flex-col sm:flex-row gap-6 sm:gap-0 items-center justify-between">
        <ul className="icons-list flex items-center gap-[15px]">
        <li>
          <a href="https://discord.gg/furlough" target="_blank">
              <i className="fa-brands fa-discord"></i>
            </a>
            </li>
            <li>
            <a href="https://instagram.com/furlough" target="_blank">
              <i className="fa-brands fa-instagram"></i>
            </a>
          </li>
          <li>
            <a href="https://facebook.com/furloughgroup" target="_blank">
              <i className="fa-brands fa-facebook"></i>
            </a>
          </li>
          <li>
            <a href="https://linkedin.com/company/furlough" target="_blank">
              <i className="fa-brands fa-linkedin-in"></i>
            </a>
          </li>
          <li>
            <a href="https://furloughtv.com" target="_blank">
              <i className="fa-brands fa-youtube"></i>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/furloughgroup" target="_blank">
              <i className="fa-brands fa-x-twitter"></i>
            </a>
          </li>
          <li>
            <a href="https://www.tiktok.com/@furlough.com" target="_blank">
              <i className="fa-brands fa-tiktok"></i>
            </a>
          </li>
        </ul>
        <nav className={classNames({ active: isActive })}>
        <ul className="flex items-center">
          {/*<li>
            <Link to="/proposals">DAO</Link>
          </li>*/}
          <li>
          <Link to="/playground">Playground</Link>
          </li>

        </ul>

      </nav>
        <div className="LOGIN_BTN_WRAPPER ">
        {/*<button
            className="LOGIN_BTN"
            onClick={discordToken ? discordLogout : openAuthPopup}
          >
            <span className="text-base font-[Archivo]">
              {" "}
              {discordToken ? "Logout" : "Login"}
            </span>
          </button>*/}
          <button
            className="LOGIN_BTN"
            onClick={localStorage.getItem("allowed")!=="1" ? adminLogin : adminLogout}
          >
            <span className="text-base font-[Archivo]">
              {" "}
              {localStorage.getItem("allowed")=="1" ? "Logout" : "Admin Login"}
            </span>
          </button>
        {/*{localStorage.getItem("allowed") == "1" ? (
                    <a href="/login">
                    <button className="LOGIN_BTN"><span className="text-base font-[Archivo]">Admin Logout</span></button>
                    </a>
                  ) : (
                    <a href="/login">
          <button className="LOGIN_BTN"><span className="text-base font-[Archivo]">Admin Login</span></button>
          </a>
                  )}*/}
        
        </div>
      </div>
    </footer>
  );
};
