import React from "react";
import { LoaderboardAvatar } from "./LoaderboardAvatar";
import "./styles.css";

export const LeaderboardItem = ({ item, index }) => {
  return (
    <div className="LEADERBOAR_ITEM">
      <div className="flex items-center gap-8">
        <span>{index}.</span>
        <LoaderboardAvatar img={item.avatar} username={item.nickname} />
      </div>
      <span>{item.totalComments}</span>
    </div>
  );
};
