import { useWallet} from "@solana/wallet-adapter-react";
import { Homepage } from "./views/Homepage";
import { Createproposal } from "./views/Createproposal";
import { Playground } from "./views/playGround";
import { Playgroundembed } from "./views/playGroundEmbed";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Proposals } from "./views/Proposals";
import { Vote } from "./views/vote";
import { Login } from "./views/login";
import { Profile } from "./views/profile";
import { Header } from "./components/Header";
import { AdminHeader } from "./components/AdminHeader";
import { Footer } from "./components/Footer/";
import { DiscordCallback } from "./components/DiscordCallback";
import { Createfaucet } from "./views/admin/create_faucet";
import { FundReload } from "./views/admin/fund_reload";
import { Faucetlisting } from "./views/admin/faucet_list";
import { Createcollection } from "./views/admin/new_token";
import { Updatecollection } from "./views/admin/update_token";
import { Tokenlisting } from "./views/admin/token_list";
import { CreateFaucetToken } from "./views/admin/create_faucet_token";
import MintSBToken from "./views/admin/mint_sb_token";
import {WalletFund} from "./views/admin/wallet_funds";
import { LeaderboardPage } from "./views/leaderboard";
export default function Router() {
  let wallet = useWallet();
  const location = useLocation();
  if (!location.pathname.startsWith("/admin")) {
    return (
      <>
        {location.pathname !== "/playgroundembed" ? <Header /> : null}
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/leaderboard" element={<LeaderboardPage />} />
          <Route path="/createproposal" element={<Createproposal />} />
          <Route path="/playground" element={<Playground />} />
          <Route path="/playgroundembed" element={<Playgroundembed />} />
          <Route path="/vote" element={<Vote />} />
          <Route path="/proposals" element={<Proposals />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/profile"
            element={
              wallet.connected == true ? (
                <Profile />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          {/*<Route path="/profile" element={<Profile />} />*/}
          <Route path="/auth/discord/callback/" element={<DiscordCallback />} />
          <Route
            path="/mintSBToken/:faucetId/:collectionAddress"
            element={
                <MintSBToken />
            }
          />
        </Routes>
        {location.pathname !== "/playgroundembed" ? <Footer /> : null}
      </>
    );
  } else {
    return (
      <>
        <AdminHeader />
        <Routes>
          <Route
            path="/admin/faucetlist"
            element={
              localStorage.getItem("allowed") == "1" ? (
                <Faucetlisting />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/admin/creatfaucet"
            element={
              localStorage.getItem("allowed") == "1" ? (
                <Createfaucet />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/admin/fundreload"
            element={
              localStorage.getItem("allowed") == "1" ? (
                <FundReload />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/admin/walletfund"
            element={
              localStorage.getItem("allowed") == "1" ? (
                <WalletFund />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/admin/creatFaucetToken"
            element={
              localStorage.getItem("allowed") == "1" ? (
                <CreateFaucetToken />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/admin/tokenlist"
            element={
              localStorage.getItem("allowed") == "1" ? (
                <Tokenlisting />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/admin/newtoken"
            element={
              localStorage.getItem("allowed") == "1" ? (
                <Createcollection />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          
          {/*<Route
            path="/admin/mintSBToken/:faucetId/:collectionAddress"
            element={
              localStorage.getItem("allowed") == "1" ? (
                <MintSBToken />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />*/}
          <Route
            path="/admin/updatetoken/:collectionAddress"
            element={
              localStorage.getItem("allowed") == "1" ? (
                <Updatecollection />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        </Routes>
        <Footer />
      </>
    );
  }
}
