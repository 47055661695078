import React, { useEffect, useState } from "react";
import { InputField } from "../../components/CutomUiFields";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { createFaucetSchema } from "../../utils/schemas";
import BlackSelect from "../../components/BlackSelect";
import { useNavigate } from "react-router-dom";
import {Dialog, FormControl} from "@mui/material";

type FormValues = {
  title: string;
  type: string;
  collectionaddress: string;
};

export const Createfaucet = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [tokens, setToken] = useState([]);
  const [values, setValues] = React.useState({
    collectionaddress: "",
    type: "",
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(createFaucetSchema),
  });

  useEffect(() => {
    (async () => {
      try {
        const requestOptions = {
          method: "POST",
          headers: {
            authorization: process.env.REACT_APP_Token as string,
          },
        };
        const res = await fetch(
          `${process.env.REACT_APP_URL}/api/allCollections`,
          requestOptions
        );
        const { data, statusCode } = await res.json();
        if (statusCode == 200) {
          const normalizedTokens = data.map((el) => ({
            id: el.nftaddress,
            value: el.title,
          }));
          setToken(normalizedTokens);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log({ ...values, [name]: event.target });
    setValues((prev) => ({ ...prev, [name]: value }));
    setValue(name, value.id, { shouldValidate: true });
  };

  const onSubmit = async (values) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        authorization: process.env.REACT_APP_Token as string,
      },
      body: new URLSearchParams(Object.entries(values)).toString(),
    };
    const res = await fetch(
      `${process.env.REACT_APP_URL}/api/createFaucet`,
      requestOptions
    );
    const data = await res.json();
    if (data?.statusCode == 200) {
      setSuccess(true);
    }
  };

  return (
    <>
      <section className="create__faucet">

        <div className={'container container-form section-container-form'}>
          <div className="ft-form-container">
            <div className="ft-bg"></div>

            <form onSubmit={handleSubmit(onSubmit)}>


              <div className="ft-form">

                <div className={'ft-form-title'}>
                  Customize Faucet
                </div>


                <FormControl fullWidth className="mb-4">
                  <InputField
                    fullWidth
                    id="standard-basic"
                    label="Name"
                    variant="standard"
                    {...register("title")}
                    error={!!errors.title}
                    helperText={errors.title?.message}
                  />
                </FormControl>

                <BlackSelect
                  value={values.type}
                  handleChange={handleChange}
                  data={[
                    { id: "url", value: "Link" },
                    { id: "qr", value: "Dynamic Qr Code" },
                  ]}
                  label="Faucet Type"
                  name="type"
                  error={errors.type?.message}
                />
                <BlackSelect
                  value={values.collectionaddress}
                  handleChange={handleChange}
                  data={tokens}
                  name="collectionaddress"
                  label="Token"
                  error={errors.collectionaddress?.message}
                />

                <div className="">
                  <button className="ft-button w-full" data-component-style={"1"}>
                    SUBMIT
                  </button>
                </div>

              </div>
            </form>

          </div>
        </div>
      </section>

      <Dialog onClose={() => setSuccess(false)} open={success}>
        <div className="p-5">
          <h2 className="mb-4">Faucet Created Successfully</h2>

          <button
            className="px-3 py-2 bg-[#212342] text-white rounded-md"
            onClick={() => navigate("/admin/faucetlist")}
          >
            Close
          </button>
        </div>
      </Dialog>
    </>
  );
};
